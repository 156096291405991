import { BufferView } from "./BufferView"

export type RenderableObjectUniform = {
    kind: 'bool' | 'int' | 'float' | 'vec2' | 'vec3' | 'vec4' | 'mat4',
    location: WebGLUniformLocation,
}

type RenderableObjectAttribute = {
    location: number,
    bufferView: BufferView,
    divisor: number,
    locationOffset: number,
}

export class RenderableObject {
    gl: WebGL2RenderingContext
    mode: number
    program: WebGLProgram
    vao: WebGLVertexArrayObject
    attributes: RenderableObjectAttribute[]
    uniforms: Record<string, RenderableObjectUniform>
    indices?: BufferView
    count: number
    instanceCount?: number

    constructor(
        gl: WebGL2RenderingContext,
        vertexShader: WebGLShader,
        fragmentShader: WebGLShader,
        mode: number,
        count: number,
    ) {
        const program = gl.createProgram()!;
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        const vao = gl.createVertexArray()!;

        this.gl = gl
        this.mode = mode
        this.program = program
        this.vao = vao
        this.attributes = []
        this.uniforms = {}
        this.count = count
    }

    setIndices(bufferView: BufferView) {
        this.indices = bufferView;
    }

    setUniform(name: string, value: any) {
        const uniform = this.uniforms[name];
        const location = uniform.location;
        const gl = this.gl

        switch (uniform.kind) {
            case 'bool':
                gl.uniform1i(location, value)
                break
            case 'int':
                if (typeof value === 'number') {
                    gl.uniform1i(location, value)
                }
                else {
                    gl.uniform1iv(location, value)
                }
                break
            case 'float':
                if (typeof value === 'number') {
                    gl.uniform1f(location, value)
                }
                else {
                    gl.uniform1fv(location, value)
                }
                break
            case 'vec2':
                gl.uniform2fv(location, value)
                break
            case 'vec3':
                gl.uniform3fv(location, value)
                break
            case 'vec4':
                gl.uniform4fv(location, value)
                break
            case 'mat4':
                gl.uniformMatrix4fv(location, false, value)
                break
        }
    }
    setUniform1i(name: string, value: number) {
        this.gl.uniform1i(this.uniforms[name].location, value);
    }

    getAttribLocation(name: string): number {
        return this.gl.getAttribLocation(this.program, name)
    }

    setAttrib(loc: number, bufferView: BufferView, locationOffset: number, divisor: number) {
        const location = loc + locationOffset;

        const gl = this.gl
        gl.bindVertexArray(this.vao);
        gl.bindBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], bufferView.buffer);
        gl.enableVertexAttribArray(location);

        if (bufferView.componentType == WebGL2RenderingContext['FLOAT']) {
            gl.vertexAttribPointer(
                location, bufferView.size, bufferView.componentType, false, bufferView.stride, bufferView.offset
            );
        }
        else {
            gl.vertexAttribIPointer(
                location, bufferView.size, bufferView.componentType, bufferView.stride, bufferView.offset
            );
        }

        if (divisor != 0) {
            gl.vertexAttribDivisor(location, divisor);
        }
        this.attributes[location] = {
            location,
            bufferView,
            divisor,
            locationOffset,
        }

        gl.bindVertexArray(null);
        gl.bindBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], null)
    }

    addUniform(name: string, kind: RenderableObjectUniform['kind']): boolean {
        const location = this.gl.getUniformLocation(this.program, name);

        if (location === null) {
            console.warn(`Uniform not found: [${name}]`)
            return false
        }

        this.uniforms[name] = { kind, location };
        return true
    }

    getProgram(): WebGLProgram {
        return this.program
    }

    useProgram() {
        this.gl.useProgram(this.program);
    }
    bindVertexArray() {
        this.gl.bindVertexArray(this.vao);
    }

    render() {
        const gl = this.gl
        this.useProgram();
        this.bindVertexArray();
        if (this.indices !== undefined) {
            gl.bindBuffer(WebGL2RenderingContext['ELEMENT_ARRAY_BUFFER'], this.indices.buffer);
            if (this.instanceCount !== undefined) {
                gl.drawElementsInstanced(this.mode, this.count, this.indices.componentType, this.indices.offset, this.instanceCount)
            }
            else {
                gl.drawElements(this.mode, this.count, this.indices.componentType, this.indices.offset);
            }
        }
        else {
            if (this.instanceCount !== undefined) {
                gl.drawArraysInstanced(this.mode, 0, this.count, this.instanceCount);
            }
            else {
                gl.drawArrays(this.mode, 0, this.count);
            }
        }
        gl.bindVertexArray(null);
    }
}
