import { Engine } from "../Engine"
import { HUD, HUDElement } from "../engine/HUD"
import { Scene } from "../engine/Scene"

class Menu extends HUDElement {
    constructor() {
        const container = document.createElement('div')
        super(container)

        container.classList.add('snake-menu')

        const title = document.createElement('h1')
        title.classList.add('title')
        title.innerText = 'SNAKE'
        container.appendChild(title)

        const menu = document.createElement('div')
        menu.classList.add('menu')
        container.appendChild(menu)

        const newGameBtn = document.createElement('div')
        newGameBtn.classList.add('button')
        newGameBtn.innerText = 'new game'
        newGameBtn.addEventListener('click', () => {
            Engine.changeScene('snake')
        })
        menu.appendChild(newGameBtn)
    }
}

export class SnakeMenuScene extends Scene {
    async Setup() {
        HUD.add(new Menu())
    }
}
