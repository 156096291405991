export class BufferView {
    buffer: WebGLBuffer
    size: number
    componentType: number
    stride: number
    offset: number

    constructor(buffer: WebGLBuffer, size: number, componentType: number, stride: number, offset: number) {
        this.buffer = buffer
        this.size = size
        this.componentType = componentType
        this.stride = stride
        this.offset = offset
    }
}

