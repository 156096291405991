import { Engine } from '../Engine';
import { Events } from './Events';
import { GameObject } from './GameObject';
import { Manager } from './Manager';
import { TransformManager } from './Transform';

export class InstanceManager extends Manager {
    private nonInitializedGameObjects: Array<GameObject> = []
    private instantiatedGameObjects: Array<GameObject> = []

    update(): void {
        const tmp = this.nonInitializedGameObjects
        this.nonInitializedGameObjects = []
        for (const gameObject of tmp) {
            Events.bindEvents(gameObject, gameObject)

            gameObject._init()

            this.instantiatedGameObjects.push(gameObject)

            gameObject.emit('Init');
        }
    }

    enterScene(): void {
        this.instantiatedGameObjects = [];
        this.nonInitializedGameObjects = [];
    }
    exitScene(): void {
        this.nonInitializedGameObjects = [];
        while (this.instantiatedGameObjects.length > 0) {
            console.log(this.instantiatedGameObjects)
            this.Destroy(this.instantiatedGameObjects[0]);
        }
        this.instantiatedGameObjects = [];
    }

    Instantiate<T extends GameObject>(gameObject: T): T {
        if (gameObject.instantiated) {
            console.error(
                `Error: GameObject[${gameObject.constructor.name}] already instantiated`
            );
        }
        this.nonInitializedGameObjects.push(gameObject);
        gameObject.instantiated = true;

        if (gameObject.transform.children) {
            for (const child of gameObject.transform.children) {
                this.Instantiate(child.gameObject);
            }
        }

        return gameObject;
    }

    private DestroyRecursive<T extends GameObject>(gameObject: T): void {
        if (gameObject.destroyed === true) {
            console.error('Error: Destroy called twice on same object');
        }

        if (gameObject.transform.children) {
            for (const child of gameObject.transform.children) {
                this.DestroyRecursive(child.gameObject);
            }
        }

        if (gameObject.instantiated === true && gameObject.initialized === false) {
            this.nonInitializedGameObjects = this.nonInitializedGameObjects.filter(go => go !== gameObject)
        }
        gameObject.emit('Destroy');

        gameObject._destroy();
        Events.removeOwnerListeners(gameObject);

        const index = this.instantiatedGameObjects.indexOf(gameObject)
        if (index !== -1) {
            this.instantiatedGameObjects.splice(index, 1)
        }
        else {
            console.error("Error: Could not find GameObject")
        }
    }

    Destroy<T extends GameObject>(gameObject: T): void {
        if (gameObject.transform.parent !== undefined) {
            TransformManager.removeChild(gameObject.transform.parent, gameObject.transform)
        }
        this.DestroyRecursive(gameObject)
    }
}


export const Instance = new InstanceManager()
