// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ManagerConstructor = new () => Manager;

export abstract class Manager {
    async Init?(): Promise<void>;

    async Setup?(): Promise<void>;
    Exit?(): void;

    Destructor?(): void;
}

