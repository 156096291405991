import { mat4, quat, vec2, vec3 } from "gl-matrix";
import { Engine } from "../Engine";
import { Events } from "../engine/Events";
import { GameObject } from "../engine/GameObject";
import { FREE_INDEX0, Graphics, MeshComponent } from "../engine/Graphics";
import { AudioSourceComponent, AudioSystem } from "../engine/graphics/Audio";
import { BufferView } from "../engine/graphics/BufferView";
import { CameraComponent } from "../engine/graphics/Camera";
import { Geometry } from "../engine/graphics/Geometry";
import { GLTFMesh } from "../engine/graphics/GLTFLoader";
import { Material } from "../engine/graphics/Material";
import { InstancedMesh, Mesh } from "../engine/graphics/Mesh";
import { HUD, HUDElement } from "../engine/HUD";
import { Inputs } from "../engine/Inputs";
import { Instance } from "../engine/Instance";
import { Scene } from "../engine/Scene";
import { Time } from "../engine/Time";
import { snakeSegmentVSSrc } from "./SnakeSegmentVS";

const rotMoves = [
    vec3.fromValues(0, 1, 0),
    vec3.fromValues(1, 0, 0),
    vec3.fromValues(0, -1, 0),
    vec3.fromValues(-1, 0, 0),
]

function smoothStep(x: number): number {
    return x * x * (3 - 2 * x)
}

class Helper extends GameObject {
    head: GameObject

    constructor(buffer: WebGLBuffer, count: number, headPosition: vec3) {
        super()

        const geometry = Geometry.cuboid(0.4, 0.4, 0.4)
        const material = Material.Default()
            .useColor(vec3.fromValues(0, 1, 1))
            .setMetalness(0)
            .setRoughness(1)

        Graphics.addMeshComponent(
            this,
            new InstancedMesh(
                geometry,
                material,
                buffer,
                count
            )
        )
        const head = new GameObject()
        Graphics.addMeshComponent(head, new Mesh(
            geometry,
            material
        ))
        vec3.copy(head.transform.position, headPosition)
        this.head = this.addChild(head)
    }
}

class SnakeSection extends GameObject {
    mesh: GLTFMesh
    globalRots: number[]
    rots: Int32Array
    positions: vec3[]

    matrices: mat4[]
    matricesBuffer: Float32Array
    matricesGLBuffer: WebGLBuffer

    numSegments: number

    statesBuffer: WebGLBuffer

    grew = false

    constructor(numSegments: number) {
        super()

        this.numSegments = numSegments

        const materials = [
            Material.Default({ vertexShader: snakeSegmentVSSrc })
                .useColor(vec3.fromValues(1, 0.2, 0.1))
                .useNormals()
                .setRoughness(0.3)
                .setMetalness(0),
            Material.Default({ vertexShader: snakeSegmentVSSrc })
                .useColor(vec3.fromValues(0.7, 0.7, 0.1))
                .useNormals()
                .setRoughness(0.3)
                .setMetalness(0),
        ]
        materials[0].uniforms['uT'] = {
            type: 'float',
            value: new Float32Array([0]),
        }
        materials[0].uniforms['uGrowing'] = {
            type: 'int',
            value: new Int32Array([0])
        }
        materials[1].uniforms['uT'] = {
            type: 'float',
            value: new Float32Array([0]),
        }
        materials[1].uniforms['uGrowing'] = {
            type: 'int',
            value: new Int32Array([0])
        }

        this.globalRots = Array(this.numSegments).fill(0)
        this.rots = new Int32Array(Array(this.numSegments * 2).fill(0))
        this.positions = Array(this.numSegments).fill(null).map((_, i) => vec3.fromValues(0, i, 0))

        this.matricesBuffer = new Float32Array(16 * this.numSegments)
        this.matrices = Array(this.numSegments).fill(null).map((_, i) => {
            const q = quat.create()
            quat.rotateZ(q, q, -this.globalRots[i] * Math.PI / 2)
            return mat4.fromRotationTranslation(
                this.matricesBuffer.subarray(i * 16, i * 16 + 16),
                q,
                this.positions[i]
            )
        })
        this.matricesGLBuffer = Graphics.createBuffer(
            WebGL2RenderingContext['ARRAY_BUFFER'],
            this.matricesBuffer,
            WebGL2RenderingContext['DYNAMIC_COPY']
        )

        this.statesBuffer = Graphics.createBuffer(
            WebGL2RenderingContext['ARRAY_BUFFER'],
            this.rots,
            WebGL2RenderingContext['DYNAMIC_COPY']
        )

        const model = Graphics.getGLTFModel('snake_section')!
        model.meshInstances.forEach(instance => {
            instance.primitives.map((p, i) => {
                const mesh = (p.mesh as InstancedMesh)
                mesh.setLocalTransformsBuffer(this.matricesGLBuffer)
                mesh.setInstanceCount(this.numSegments)
                mesh.instancedAttributes = [
                    {
                        name: 'uState',
                        bufferView: new BufferView(
                            this.statesBuffer,
                            2,
                            WebGL2RenderingContext['INT'],
                            0, 0
                        ),
                        offset: 0,
                        divisor: 1,
                    },
                ]
                mesh.setMaterial(materials[i % 2])
            })
        })
        const mesh = model
        this.mesh = mesh
        Graphics.addMeshComponent(this, mesh)
    }
    update(dir: number, expansion: boolean) {
        const n = this.numSegments

        if (expansion) {
            this.globalRots.push((this.globalRots[n - 1] + this.rots[(n - 1) * 2 + 1]) % 4)
            const newRots = new Int32Array(this.rots.length + 2)
            newRots.set(this.rots, 0)
            newRots[n * 2 + 0] = this.rots[(n - 1) * 2 + 1]
            newRots[n * 2 + 1] = (dir - this.globalRots[n] + 4) % 4
            this.rots = newRots
            this.positions.push(vec3.add(vec3.create(), this.positions[n - 1], rotMoves[this.globalRots[n]]))

            this.matricesBuffer = new Float32Array(16 * this.numSegments + 16)
            this.matrices = Array(this.numSegments + 1).fill(null).map((_, i) => {
                const q = quat.create()
                quat.rotateZ(q, q, -this.globalRots[i] * Math.PI / 2)
                return mat4.fromRotationTranslation(
                    this.matricesBuffer.subarray(i * 16, i * 16 + 16),
                    q,
                    this.positions[i]
                )
            })
            Graphics.context.bindBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], this.matricesGLBuffer)
            Graphics.context.bufferData(WebGL2RenderingContext['ARRAY_BUFFER'], this.matricesBuffer, WebGL2RenderingContext['DYNAMIC_COPY'])

            Graphics.context.bindBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], this.statesBuffer)
            Graphics.context.bufferData(WebGL2RenderingContext['ARRAY_BUFFER'], this.rots, WebGL2RenderingContext['DYNAMIC_COPY'])

            this.numSegments++
            this.mesh.meshInstances.forEach(m => m.primitives.forEach(p => (p.mesh as InstancedMesh).setInstanceCount(this.numSegments)))
            this.grew = true
        }
        else {
            for (let i = 0; i < n - 1; i++) {
                this.globalRots[i] = this.globalRots[i + 1]
                this.rots[i * 2 + 0] = this.rots[i * 2 + 2]
                this.rots[i * 2 + 1] = this.rots[i * 2 + 3]
                vec3.copy(this.positions[i], this.positions[i + 1])
            }
            this.globalRots[n - 1] = (this.globalRots[n - 1] + this.rots[(n - 1) * 2 + 1]) % 4
            this.rots[(n - 1) * 2 + 0] = this.rots[(n - 1) * 2 + 1]
            this.rots[(n - 1) * 2 + 1] = (dir - this.globalRots[n - 1] + 4) % 4
            vec3.add(this.positions[n - 1], this.positions[n - 1], rotMoves[this.globalRots[n - 1]])

            for (let i = 0; i < n; i++) {
                const q = quat.create()
                quat.rotateZ(q, q, -this.globalRots[i] * Math.PI / 2)
                mat4.fromRotationTranslation(this.matrices[i], q, this.positions[i])
            }

            Graphics.context.bindBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], this.matricesGLBuffer)
            Graphics.context.bufferData(WebGL2RenderingContext['ARRAY_BUFFER'], this.matricesBuffer, WebGL2RenderingContext['DYNAMIC_COPY'])

            Graphics.context.bindBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], this.statesBuffer)
            Graphics.context.bufferData(WebGL2RenderingContext['ARRAY_BUFFER'], this.rots, WebGL2RenderingContext['DYNAMIC_COPY'])

            this.grew = false
        }
    }
    updateRender(t: number) {
        this.mesh.meshInstances.forEach(m => {
            m.primitives.forEach(p => {
                const ro = p.mesh.ro
                ro.useProgram()
                ro.setUniform('uT', new Float32Array([t]))
                ro.setUniform('uGrowing', new Int32Array([this.grew ? 1 : 0]))
            })
        })
    }
}

class SnakeHead extends GameObject {
    constructor() {
        super()

        const child = new GameObject()

        Graphics.addMeshComponent(
            child,
            new Mesh(
                Geometry.cuboid(0.2, 0.3, 0.1),
                Material.Default()
                    .useColor(vec3.fromValues(1, 0.2, 0.1))
                    .setMetalness(0)
                    .setRoughness(0.1)
            )
        )

        child.transform.position[1] = 0.3
        child.transform.position[2] = 0.12

        this.addChild(child)
    }
    updateRender(p: vec3, r: number, dir: number, t: number) {
        const move = vec3.fromValues(0, 0, 0)
        if (dir !== 0) {
            vec3.rotateZ(move, move, vec3.fromValues(dir === 1 ? 0.5 : -0.5, 0, 0), -(t - 1) * Math.PI / 2 * (dir === 1 ? 1 : -1))
            quat.fromEuler(this.transform.rotation, 0, 0, -(dir === 1 ? 1 : -1) * t * 90 + (dir === 1 ? 90 : -90))
        }
        else {
            move[1] = t - 1
            quat.identity(this.transform.rotation)
        }
        move[1] -= 0.5
        vec3.rotateZ(move, move, vec3.create(), -Math.PI * r / 2)
        quat.rotateZ(this.transform.rotation, this.transform.rotation, -Math.PI * r / 2)

        vec3.add(this.transform.position, p, move)
    }
}

class Snake extends GameObject {
    body: SnakeSection
    head: SnakeHead

    dir = 0
    headOrientation = 0

    constructor() {
        super()

        this.body = this.addChild(new SnakeSection(4))
        this.head = this.addChild(new SnakeHead())
        this.transform.position[2] = 0.05
    }
    Init() {
        console.log(...this.transform.position)
    }

    update(dir: number, expansion: boolean) {
        this.headOrientation = dir
        this.body.update(dir, expansion)
    }

    updateRender(t: number) {
        const animationT = smoothStep(Math.min(1, t))
        this.body.updateRender(animationT)
        this.head.updateRender(
            vec3.add(vec3.create(), this.body.positions[this.body.numSegments - 1], rotMoves[this.headOrientation]),
            this.headOrientation,
            this.body.rots[this.body.rots.length - 1],
            animationT
        )
    }
    getHeadPosition(): vec3 {
        return vec3.add(vec3.create(), this.body.positions[this.body.numSegments - 1], rotMoves[this.headOrientation])
    }
}

class Camera extends GameObject {
    constructor(gridWidth: number, gridHeight: number) {
        super()

        this.addComponent(new AudioSourceComponent(this))

        this.addComponent(new CameraComponent(
            this, {
                type: 'orthographic',
                width: 10,
                aspect: Graphics.canvasWidth / Graphics.canvasHeight,
                near: 0.01,
                far: 50
            }
        ))
        /*
        const s = 300
        this.addComponent(new CameraComponent(
            this, {
                type: 'perspective',
                fovy: 0.01 * Math.PI,
                aspect: Graphics.canvasWidth / Graphics.canvasHeight,
                near: s - 10,
                far: s + 10
            }
        ))
        this.transform.position[2] = s
        */

        this.transform.lookAt(
            vec3.fromValues(7, 0, 3.5),
            vec3.fromValues(3.5, 3.5, 0),
            vec3.fromValues(0, 0, 1)
        )
    }

    Init() {
        Graphics.camera = this.getComponent(CameraComponent)
        this.getComponent(AudioSourceComponent).play('test')
    }
}

class Ambient extends GameObject {
    constructor() {
        super()

        Graphics.lightManager.addAmbientLightComponent(
            this,
            vec3.fromValues(1, 1, 1),
            0.4
        )
        Graphics.lightManager.addDirectionalLightComponent(
            this,
            {
                color: vec3.fromValues(1, 1, 1),
                intensity: 0.8,
                shadowMapsNum: 3,
            }
        )
        /*
        vec3.set(this.transform.position, 3.5, 3.5, 4)
        quat.rotateY(this.transform.rotation, this.transform.rotation, 0.5)
        quat.rotateX(this.transform.rotation, this.transform.rotation, -0.7)
        */
        /*
        Graphics.lightManager.addSpotLightComponent(this, {
            color: vec3.fromValues(1, 1, 1),
            intensity: 50,
            radius: 30,
            halfAngle: Math.PI * .5 * 0.3,
            shadowMap: true
        })
        */
        /*
        Graphics.lightManager.addPointLightComponent(this, {
            color: vec3.fromValues(1, 1, 1),
            intensity: 50,
            radius: 30,
            type: 'standard',
        })
        */
        this.transform.lookAt(
            vec3.fromValues(3.5, 3.5 - 5, 0 + 10),
            vec3.fromValues(3.5, 3.5, 0),
            vec3.fromValues(0, 0, 1)
        )
    }
}

class Fruit extends GameObject {
    constructor() {
        super()

        Graphics.addMeshComponent(
            this,
            new Mesh(
                Geometry.sphere(0.3, 2),
                Material.Default()
                    .setRoughness(0.3)
                    .useNormals()
                    .useColor(vec3.fromValues(1, 0.5, 0.1))
            )
        )
        this.transform.position[2] = 0.3
    }
    getEaten() {
        Instance.Destroy(this)
    }
}

class Grid extends GameObject {
    constructor() {
        super()

        Graphics.addMeshComponent(this, Graphics.getGLTFModel('terrain')!)

        vec3.set(this.transform.position, 3.5, 3.5, 0)
    }
}

class Game extends GameObject {
    width: number
    height: number

    snake: Snake
    t = 1
    grid: Uint32Array
    nextDir = 0
    running = false

    hasLost = false

    helper: Helper

    fruit?: Fruit

    constructor(width: number, height: number) {
        super()

        this.width = width
        this.height = height

        this.addChild(new Grid())
        this.snake = this.addChild(new Snake())

        const transformArray = Array(this.width * this.height).fill(null).map((_, i) => {
            const x = i % this.width
            const y = Math.floor(i / this.width)

            return mat4.fromTranslation(mat4.create(), [x, y, -0.025])
        })

        const color = vec3.fromValues(0.3, 0.8, 0.3)
        Graphics.addMeshComponent(
            this,
            new InstancedMesh(
                Geometry.cuboid(0.4, 0.4, 0.05),
                Material.Default()
                    .useColor(color)
                    .setMetalness(0)
                    .setRoughness(1)
                ,
                transformArray,
                this.width * this.height
            )
        )

        this.grid = new Uint32Array(this.width * this.height)

        for (const p of this.snake.body.positions) {
            this.grid[p[0] + p[1] * this.width] = 1
        }
        const headPosition = this.snake.getHeadPosition()
        this.grid[headPosition[0] + headPosition[1] * this.height] = 1

        this.spawnFruit()

        /*
        this.helper = this.addChild(new Helper(
            this.snake.body.matricesGLBuffer,
            this.snake.body.numSegments,
            vec3.add(vec3.create(), this.snake.body.positions[this.snake.body.numSegments - 1], rotMoves[this.snake.headOrientation])
        ))
        */
    }

    indexFromPos(x: number, y: number): number {
        return x + y * this.width
    }

    spawnFruit() {
        const tiles = this.grid.reduce((acc: number[], x, i) => {
            if (x === 0)
                acc.push(i)
            return acc
        }, [])
        if (tiles.length <= 0) {
            console.log('WIN')
            return
        }
        const tile = tiles[Math.floor(Math.random() * tiles.length)]
        this.fruit = this.addChild(new Fruit())
        this.fruit.transform.position[0] = tile % this.width
        this.fruit.transform.position[1] = Math.floor(tile / this.width)
        this.grid[tile] = 2
        console.log(this.grid[tile])
    }

    Update() {
        if (this.hasLost === false) {
            if (Inputs.get('z') && this.snake.headOrientation !== 2) {
                this.nextDir = 0
                this.running = true
            }
            if (Inputs.get('d') && this.snake.headOrientation !== 3) {
                this.nextDir = 1
                this.running = true
            }
            if (Inputs.get('s') && this.snake.headOrientation !== 0) {
                this.nextDir = 2
                this.running = true
            }
            if (Inputs.get('q') && this.snake.headOrientation !== 1) {
                this.nextDir = 3
                this.running = true
            }
            if (this.running !== false) {
                this.t += Time.deltaTime * 5
            }
        }
        else {
            this.t = Math.min(0.5, this.t + Time.deltaTime * 5)
        }
        if (this.t > 3) {
            this.t = this.t - Math.floor(this.t)
            const tailPosition = vec3.clone(this.snake.body.positions[0])

            const headLastPosition = this.snake.getHeadPosition()
            const headNewPosition = vec3.add(headLastPosition, headLastPosition, rotMoves[this.nextDir])
            const headIndex = headNewPosition[0] + headNewPosition[1] * this.width

            if (
                headNewPosition[0] < 0 || headNewPosition[1] < 0 || headNewPosition[0] >= this.width || headNewPosition[1] >= this.height
            ) {
                this.hasLost = true
                Events.triggerEvent('GameOver')
            }
            const hasEaten = this.grid[headIndex] === 2
            this.snake.update(this.nextDir, hasEaten)
            //vec3.copy(this.helper.head.transform.position, this.snake.getHeadPosition())
            if (hasEaten === false) {
                this.grid[this.indexFromPos(tailPosition[0], tailPosition[1])] = 0
            }

            if (this.grid[headIndex] === 1) {
                this.hasLost = true
                Events.triggerEvent('GameOver')
            }

            if (this.hasLost === false) {
                this.grid[headIndex] = 1
                if (hasEaten) {
                    this.fruit!.getEaten()
                    this.spawnFruit()
                }
            }
        }

        this.snake.updateRender(Math.min(1, this.t))
    }
}

class LossScreen extends HUDElement {
    constructor() {
        const container = document.createElement('div')

        super(container)

        container.classList.add('hide', 'loss-container')
        
        const panel = document.createElement('div')
        panel.classList.add('panel')
        container.appendChild(panel)

        const title = document.createElement('div')
        title.innerText = 'GAME OVER'
        panel.appendChild(title)

        const restartButton = document.createElement('button')
        restartButton.innerText = 'RESTART'
        restartButton.addEventListener('click', () => Engine.changeScene('snake'))
        panel.appendChild(restartButton)

        const menuButton = document.createElement('button')
        menuButton.innerText = 'MENU'
        menuButton.addEventListener('click', () => Engine.changeScene('snakeMenu'))
        panel.appendChild(menuButton)

        Events.addEventListener('GameOver', () => {
            container.classList.remove('hide')
            //Events.removeOwnerListeners(this)
        }, this)
    }
}

export class SnakeScene extends Scene {
    static Managers = []

    static count = 0

    async Setup() {
        if (Graphics.getGLTFModel('snake_section') === undefined) {
            await Graphics.loadGLTF('snake_section', '/models/snake/snake_section.gltf', {
                buffer: Graphics.createBuffer(
                    WebGL2RenderingContext['ARRAY_BUFFER'],
                    new Float32Array(0),
                    WebGL2RenderingContext['DYNAMIC_COPY']
                ),
                count: 0
            })
            await Graphics.loadGLTF('terrain', '/models/snake/terrain.gltf')
        }

        if (AudioSystem.buffers['test'] === undefined) {
            await AudioSystem.loadSound('test', '/sounds/Sakaeta machi no.mp3')
        }

        const width = 8
        const height = 8
        HUD.add(new LossScreen())
        Instance.Instantiate(new Camera(width, height))
        Instance.Instantiate(new Ambient())
        Instance.Instantiate(new Game(width, height))
    }
}
