import { mat4 } from 'gl-matrix'
import { Material } from './Material'
import { Geometry } from './Geometry'
import { Graphics } from '../Graphics'
import { RenderableObject } from './RenderableObject'
import { BufferView } from './BufferView'
import { addSetUniform } from './utils'

export class Mesh {
    private geometry: Geometry
    private material: Material

    ro: RenderableObject

    attribLocations: Record<string, number>

    initialized = false

    constructor(geometry: Geometry, material: Material) {
        this.geometry = geometry
        this.material = material

        this.init()

        this.initialized = true
    }

    setMaterial(material: Material) {
        this.material = material
        this.init()
    }

    private init() {
        const material = this.material

        const shaders = material.build()
        const ro = new RenderableObject(
            Graphics.context,
            shaders.vertex,
            shaders.fragment,
            this.geometry.mode,
            this.geometry.count
        )


        this.ro = ro

        ro.useProgram()
        ro.setIndices(this.geometry.indices!)

        this.attribLocations = {}
        Object.entries(material.attributes).forEach(([name, id]) => {
            const bv = this.geometry.attributes[id]
            const location = ro.getAttribLocation(name)
            this.attribLocations[name] = location
            ro.setAttrib(location, bv, 0, 0)
        })

        Object.entries(material.uniforms).forEach(([name, uniform]) => {
            addSetUniform(ro, name, uniform.type, uniform.value)
        })
    }

    render(transform: mat4, viewMatrix: mat4, projectionMatrix: mat4) {
        if (this.initialized === false)
            return
        const gl = Graphics.context
        for (const texture of Object.values(this.material.textures)) {
            gl.activeTexture(gl.TEXTURE0 + texture.index)
            gl.bindTexture(gl.TEXTURE_2D, texture.texture)
        }
        const ro = this.ro
        ro.useProgram()
        ro.setUniform('uTransform', transform)
        ro.setUniform('uView', viewMatrix)
        ro.setUniform('uProjection', projectionMatrix)
        ro.render()
    }
}

type InstancedAttribute = {
    name: string,
    bufferView: BufferView,
    offset: number,
    divisor: number,
}

export class InstancedMesh {
    localTransformsBuffer: WebGLBuffer

    private geometry: Geometry
    private material: Material

    ro: RenderableObject

    attribLocations: Record<string, number> = {}
    instanceCount: number

    instancedAttributes?: InstancedAttribute[]

    constructor(
        geometry: Geometry,
        material: Material,
        localTransforms: mat4[] | WebGLBuffer | null,
        count: number,
        instancedAttributes?: InstancedAttribute[]
    ) {
        const gl = Graphics.context

        this.geometry = geometry
        this.material = material

        if (localTransforms instanceof WebGLBuffer) {
            this.localTransformsBuffer = localTransforms
        }
        else {
            const data = localTransforms === null
                ? new Float32Array(count * 16 * 4)
                : new Float32Array(localTransforms.reduce((acc: number[], m: mat4) => [...acc, ...m], []))
            this.localTransformsBuffer = Graphics.createBuffer(gl.ARRAY_BUFFER, data, gl.DYNAMIC_COPY)
        }
        this.instanceCount = count

        this.instancedAttributes = instancedAttributes

        this.init()
    }

    setMaterial(material: Material) {
        this.material = material

        this.init()
    }

    private init() {
        const gl = Graphics.context
        const material = this.material
        const shaders = material.buildInstanced()

        const ro = new RenderableObject(
            gl,
            shaders.vertex,
            shaders.fragment,
            this.geometry.mode,
            this.geometry.count
        )
        this.ro = ro
        ro.useProgram()
        ro.setIndices(this.geometry.indices!)
        ro.instanceCount = this.instanceCount
        Object.entries(this.material.attributes).forEach(([name, id]) => {
            const location = ro.getAttribLocation(name)
            ro.setAttrib(location, this.geometry.attributes[id], 0, 0)
            this.attribLocations[name] = location
        })

        Object.entries(this.material.uniforms).forEach(([name, uniform]) => {
            addSetUniform(ro, name, uniform.type, uniform.value)
        })

        this.attribLocations['aLocalTransform'] = ro.getAttribLocation('aLocalTransform')
        this.setLocalTransformsBuffer(this.localTransformsBuffer)

        if (this.instancedAttributes) {
            for (const attribute of this.instancedAttributes) {
                const location = ro.getAttribLocation(attribute.name)
                ro.setAttrib(location, attribute.bufferView, attribute.offset, attribute.divisor)
            }
        }
    }

    setLocalTransformsBuffer(buffer: WebGLBuffer) {
        const ro = this.ro
        const location = this.attribLocations['aLocalTransform']
        ro.setAttrib(location, new BufferView(buffer, 4, WebGL2RenderingContext['FLOAT'], 64,  0), 0, 1)
        ro.setAttrib(location, new BufferView(buffer, 4, WebGL2RenderingContext['FLOAT'], 64, 16), 1, 1)
        ro.setAttrib(location, new BufferView(buffer, 4, WebGL2RenderingContext['FLOAT'], 64, 32), 2, 1)
        ro.setAttrib(location, new BufferView(buffer, 4, WebGL2RenderingContext['FLOAT'], 64, 48), 3, 1)
        this.localTransformsBuffer = buffer
    }
    setInstanceCount(n: number) {
        this.instanceCount = n
        this.ro.instanceCount = n
    }

    render(transform: mat4, viewMatrix: mat4, projectionMatrix: mat4) {
        const gl = Graphics.context
        for (const texture of Object.values(this.material.textures)) {
            gl.activeTexture(gl.TEXTURE0 + texture.index)
            gl.bindTexture(gl.TEXTURE_2D, texture.texture)
        }
        const ro = this.ro
        ro.useProgram()
        ro.setUniform('uTransform', transform)
        ro.setUniform('uView', viewMatrix)
        ro.setUniform('uProjection', projectionMatrix)
        ro.render()
    }
}
