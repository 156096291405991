export const snakeSegmentVSSrc = `precision highp float;

uniform mat4 uTransform;
uniform mat4 uView;
uniform mat4 uProjection;

#define PI 3.14159265359

#ifdef LOCAL_TRANSFORM
    uniform mat4 uLocalTransform;
#endif

#ifdef INSTANCED
    in mat4 aLocalTransform;
#endif

in vec3 aPosition;

#ifdef UV
    in vec2 aUV;

    out vec2 vUV;
#endif

#ifdef SKINNED
    layout(std140) uniform uJoints {
        mat4 jointMatrices[SKIN_MATRICES_NUM];
    };

    in vec4 aWeights;
    in uvec4 aJoints;
#endif

#ifdef USE_COLOR_ATTRIBUTE
    in vec3 aColor;

    out vec3 vColor;
#endif

#ifdef NORMAL
    in vec3 aNormal;

    out vec3 vNormal;
#endif

out vec3 vPosition;

in ivec2 uState;
uniform int uGrowing;
uniform float uT;

float calcShrinkFactor(const in float z) {
    return max(0., smoothstep(-1., 1., clamp((float(gl_InstanceID) + max(0., z + .5)
        + (uT - 1.) * float(uGrowing)
    ) / 3., 0., 1.)) * 2. - 1.);
}

void main() {
    mat4 transform = uTransform;
#ifdef INSTANCED
    transform = transform * aLocalTransform;
#endif
#ifdef LOCAL_TRANSFORM
    transform = transform * uLocalTransform;
#endif

    vec4 position = vec4(aPosition, 1.0);
    float offset = -1. + uT;

    vec3 mPosition = position.xyz;
    float shrinkFactor = calcShrinkFactor(mPosition.y);

    position.xz *= shrinkFactor;

    position.y += offset + 0.5;

    if (gl_InstanceID == 0 && uGrowing == 1) {
        position.y = max(0.0, position.y);
    }

    mat2 rotation = mat2(1.0, 0.0, 0.0, 1.0);

    float a = position.y * .5 * PI;
    int state = position.y >= 0. ? uState.y : uState.x;
    if (state == 1) {
        a = -a;
        rotation = mat2(cos(a), sin(a), -sin(a), cos(a));
        position.xy = rotation * vec2(position.x - 0.5, 0.0) + vec2(0.5, 0.0);
    }
    else if (state == 3) {
        rotation = mat2(cos(a), sin(a), -sin(a), cos(a));
        position.xy = rotation * vec2(position.x + 0.5, 0.0) + vec2(-0.5, 0.0);
    }
    position.y -= 0.5;

#ifdef NORMAL
    vec3 normal = aNormal;

    const float h = 0.01;

    vec3 p0 = vec3(mPosition.xz * calcShrinkFactor(mPosition.y - h), mPosition.y - h);
    p0 = p0.xzy;
    vec3 p1 = vec3(mPosition.xz * calcShrinkFactor(mPosition.y + h), mPosition.y + h);
    p1 = p1.xzy;

    vec3 pn = normalize(p1 - p0);

    normal = normalize(cross(normalize(vec3(normal.z, 0.0, -normal.x)), pn));

    normal.xy = rotation * normal.xy;
#endif

#ifdef SKINNED
    mat4 jointMat = (
        jointMatrices[aJoints.x] * aWeights.x +
        jointMatrices[aJoints.y] * aWeights.y +
        jointMatrices[aJoints.z] * aWeights.z +
        jointMatrices[aJoints.w] * aWeights.w
    );
    position = jointMat * position;
#ifdef NORMAL
    normal = mat3(jointMat) * normal;
#endif
#endif

#ifdef UV
    vUV = aUV;
#endif

#ifdef USE_COLOR_ATTRIBUTE
    vColor = aColor;
#endif
#ifdef NORMAL
    vNormal = mat3(uView * transform) * normal;
#endif

    vPosition = (uView * transform * position).xyz;
    gl_Position = uProjection * uView * transform * position;
}

`
