import { GlobalEventNames, GlobalEvents } from './EventList';
import { Manager } from './Manager';

export class EventsManager extends Manager {
    events: Record<string, { callback: (data: unknown) => void; owner: unknown }[]> = {}

    bindEvents(obj: any, owner: unknown): void {
        for (const event of GlobalEventNames) {
            if (event in obj.constructor.prototype) {
                this.addEventListener(event as keyof GlobalEvents, obj[event].bind(obj), owner);
            }
        }
    }

    addEventListener<K extends keyof GlobalEvents>(
        name: K,
        callback: (data: GlobalEvents[K]) => void,
            owner: unknown
    ): void {
        const arr = this.events[name]
        if (arr === undefined) {
            this.events[name] = [{ callback: callback as (data:unknown) => void, owner }];
        }
        else {
            arr.push({ callback: callback as (data:unknown) => void, owner });
        }
    }

    removeOwnerListeners(owner: unknown): void {
        for (const key in this.events) {
            this.events[key] = this.events[key].filter((c) => c.owner !== owner);
        }
    }

    triggerEvent<K extends keyof GlobalEvents>(
        name: K, data?: GlobalEvents[K]
    ): void {
        this.events[name]?.forEach((c) => c.callback(data));
    }

    resetEvents(): void {
        this.events = {}
    }

    deleteEvents(): void {
        this.resetEvents()
    }
}


export const Events = new EventsManager()
