import { mat4 } from "gl-matrix";
import { Component } from "../Component";
import { GameObject } from "../GameObject";
import { Transform } from "../Transform";

type OrthographicData = {
    type: 'orthographic'
    width: number
    aspect: number
    near: number
    far: number
}

type PerspectiveData = {
    type: 'perspective'
    fovy: number
    aspect: number
    near: number
    far: number
}

export class CameraComponent extends Component {
    readonly projection: mat4
    transform: Transform
    private matrix: mat4
    data: OrthographicData | PerspectiveData

    constructor(parent: GameObject, data: OrthographicData | PerspectiveData) {
        super(parent)

        this.data = data
        if (data.type === 'perspective') {
            this.projection = mat4.perspective(mat4.create(), data.fovy, data.aspect, data.near, data.far)
        }
        else {
            const w2 = data.width / 2
            this.projection = mat4.ortho(mat4.create(), -w2, w2, -w2/data.aspect, w2/data.aspect, data.near, data.far)
        }
        this.transform = this.parent.transform
        this.matrix = mat4.create()
    }
    getVueMatrix() {
        const m = this.transform.matrix
        mat4.invert(this.matrix, m)
        return this.matrix
    }
}
