import { Events } from './Events';
import { Manager } from './Manager';

export class InputsManager extends Manager {
    keyboardState = new Set();
    keyboardPressed = new Set();
    mouse = {
        move: [0, 0],
        button: [false, false, false, false, false],
    };

    private keydownCallback = ((event: KeyboardEvent) => {
        const key = event.key.toLowerCase()
        if (!this.keyboardState.has(key)) {
            this.keyboardPressed.add(key)
            this.keyboardState.add(key)
            Events.triggerEvent('OnKeyPressed', { event })
        }
    }).bind(this)
    private keyupCallback = ((event: KeyboardEvent) => {
        const key = event.key.toLowerCase()
        this.keyboardPressed.delete(key.toLowerCase())
        this.keyboardState.delete(key.toLowerCase())
    }).bind(this)

    async Init() {
        document.addEventListener('keydown', this.keydownCallback)
        document.addEventListener('keyup', this.keyupCallback)
    }
    Destructor(): void {
        document.removeEventListener('keydown', this.keydownCallback)
        document.removeEventListener('keyup', this.keyupCallback)
    }

    update(): void {
        this.mouse.move[0] = 0;
        this.mouse.move[1] = 0;
        this.mouse.button = this.mouse.button.map(() => false);
        this.keyboardPressed.clear();
    }

    get(k: string): boolean {
        return this.keyboardState.has(k);
    }
    getPressed(k: string): boolean {
        return this.keyboardPressed.has(k);
    }
    getButton(n: number): boolean {
        return this.mouse.button[n];
    }
    getMouseMoveX(): number {
        return this.mouse.move[0];
    }
    getMouseMoveY(): number {
        return this.mouse.move[1];
    }
}

export const Inputs = new InputsManager()
