import { mat4, quat, vec2, vec3, vec4 } from "gl-matrix"
import { GameObject } from "../GameObject"
import { EMISSION_INDEX, GraphicObject, Graphics } from "../Graphics"
import { ShadowMapPool } from "./ShadowMap"
import { createProgram, framebufferStatus } from "./utils"
import { PointLightComponent, PointLightOptions } from "./Light/PointLight"
import { DirectionalLightComponent, DirectionalLightOptions } from "./Light/DirectionalLight"
import { SpotLightComponent, SpotLightOptions } from "./Light/SpotLight"
import { AmbientLightComponent } from "./Light/AmbientLight"

export class EmissionPass {
    private gl: WebGLRenderingContext
    private program: WebGLProgram

    constructor(gl: WebGL2RenderingContext, shaders: [WebGLShader, WebGLShader]) {
        this.gl = gl
        this.program = createProgram(gl, shaders)
        gl.useProgram(this.program)
        gl.uniform1i(gl.getUniformLocation(this.program, 'uEmission'), EMISSION_INDEX)
    }

    pass() {
        this.gl.useProgram(this.program)
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4)
    }
}

interface Light {
    render: (viewMatrix?: mat4, projectionMatrix?: mat4) => void
}

export class LightManager {
    private gl: WebGL2RenderingContext
    private lights: Light[] = []
    private emissionPass: EmissionPass = <EmissionPass><unknown>null
    readonly lightFB: WebGLFramebuffer
    readonly lightTex: WebGLTexture

    spotLightSMs: SpotLightComponent[] = []
    directionalLightSMs: DirectionalLightComponent[] = []

    shadowMapPool: ShadowMapPool

    constructor() {
        const gl = Graphics.context
        this.gl = gl

        this.lightFB = gl.createFramebuffer() as WebGLFramebuffer
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.lightFB)

        this.lightTex = gl.createTexture() as WebGLTexture
        gl.bindTexture(gl.TEXTURE_2D, this.lightTex)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA32F, gl.canvas.width, gl.canvas.height, 0, gl.RGBA, gl.FLOAT, null)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.lightTex, 0)

        framebufferStatus(gl)

        this.shadowMapPool = new ShadowMapPool(gl)
    }

    async init() {
        this.emissionPass = new EmissionPass(this.gl,
            [
                Graphics.shaders.compileShader('basicVS', {type: 'vertex'}),
                Graphics.shaders.compileShader('emissionFS', {type: 'fragment'}),
            ]
        )
    }

    reset() {
        if (this.lights.length > 0) {
            console.log('PROBLEM')
        }
        if (this.directionalLightSMs.length > 0) {
            console.log('PROBLEM')
        }
        if (this.spotLightSMs.length > 0) {
            console.log('PROBLEM')
        }

        this.lights = []
        for (const light of this.directionalLightSMs) {
            for (const shadowMap of light.shadowMaps!) {
                this.shadowMapPool.free(shadowMap)
            }
        }
        this.directionalLightSMs = []
        for (const light of this.spotLightSMs) {
            this.shadowMapPool.free(light.shadowMap!)
        }
        this.spotLightSMs = []
    }

    renderShadowMaps(objects: GraphicObject[]) {
        const gl = this.gl

        gl.enable(gl.CULL_FACE)

        const viewMatrix = mat4.create()
        const projectionMatrix = mat4.create()

        gl.enable(gl.DEPTH_TEST)

        for (const light of this.spotLightSMs) {
            const tmp = light.parent.transform.matrix
            mat4.invert(viewMatrix, tmp)
            mat4.perspective(projectionMatrix, light.halfAngle * 2, 1, 0.01, light.radius)

            light.shadowMap!.render(objects, viewMatrix, projectionMatrix)
        }

        for (const light of this.directionalLightSMs) {
            light.update(Graphics.camera!)

            light.shadowMaps!.forEach((sm, i) => {
                const tmp = light.shadowMapTransforms![i]
                mat4.invert(viewMatrix, tmp)
                sm.render(objects, viewMatrix, light.shadowMapProjections![i])
            })
        }

        gl.disable(gl.DEPTH_TEST)
        //gl.cullFace(gl.BACK)
    }

    render(viewMatrix: mat4, projectionMatrix: mat4) {
        const gl = this.gl

        gl.bindFramebuffer(gl.FRAMEBUFFER, this.lightFB)
        gl.drawBuffers([gl.COLOR_ATTACHMENT0])

        gl.enable(gl.BLEND)
        gl.blendFunc(gl.ONE, gl.ONE)

        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)

        this.emissionPass.pass()

        for (const light of this.lights) {
            light.render(viewMatrix, projectionMatrix)
        }

        gl.disable(gl.BLEND)
    }

    addPointLightComponent(parent: GameObject, options: PointLightOptions) {
        const component = new PointLightComponent(parent, options)
        parent.addComponent(component)

        GameObject.createListener(parent, 'Init', this.pointLightInit.bind(this, component))
        GameObject.createListener(parent, 'Destroy', this.pointLightDestroy.bind(this, component))
    }

    pointLightInit(component: PointLightComponent) {
        this.lights.push(component)
    }

    pointLightDestroy(component: PointLightComponent) {
        this.removeLight(component)
    }

    addSpotLightComponent(object: GameObject, options: SpotLightOptions) {
        const component = new SpotLightComponent(object, options)
        object.addComponent(component)

        GameObject.createListener(object, 'Init', this.spotLightInit.bind(this, component))
        GameObject.createListener(object, 'Destroy', this.spotLightDestroy.bind(this, component))
    }

    spotLightInit(component: SpotLightComponent) {
        this.lights.push(component)
        if (component.hasShadowMap) {
            const shadowMap = this.shadowMapPool.get()
            component.setShadowMap(shadowMap)
            this.spotLightSMs.push(component)
        }
    }

    spotLightDestroy(component: SpotLightComponent) {
        this.removeLight(component)
        if (component.hasShadowMap) {
            this.shadowMapPool.free(component.shadowMap!)
            component.setShadowMap(null)
        }
    }

    addDirectionalLightComponent(object: GameObject, options: DirectionalLightOptions) {
        const component = new DirectionalLightComponent(object, options)
        object.addComponent(component)

        GameObject.createListener(object, 'Init', this.directionalLightInit.bind(this, component))
        GameObject.createListener(object, 'Destroy', this.directionalLightDestroy.bind(this, component))
    }

    directionalLightInit(component: DirectionalLightComponent) {
        this.lights.push(component)
        if (component.shadowMapsNum !== undefined) {
            component.setShadowMaps(Array(component.shadowMapsNum).fill(null).map(_ => this.shadowMapPool.get()))
            this.directionalLightSMs.push(component)
        }
    }

    directionalLightDestroy(component: DirectionalLightComponent) {
        if (component.hasShadowMap) {
            for (const shadowMap of component.shadowMaps!) {
                this.shadowMapPool.free(shadowMap)
            }
            const index = this.directionalLightSMs.indexOf(component)
            if (index === -1) {
                throw new Error('Could not find ShadowMap')
            }
            this.directionalLightSMs.splice(index, 1)
            component.setShadowMaps(undefined)
        }
        this.removeLight(component)
    }

    addAmbientLightComponent(parent: GameObject, color: vec3, intensity: number) {
        const component = new AmbientLightComponent(parent, color, intensity)
        parent.addComponent(component)

        GameObject.createListener(parent, 'Init', this.ambientLightInit.bind(this, component))
        GameObject.createListener(parent, 'Destroy', this.ambientLightDestroy.bind(this, component))
    }

    ambientLightInit(component: AmbientLightComponent) {
        this.lights.push(component)
    }

    ambientLightDestroy(component: AmbientLightComponent) {
        this.removeLight(component)
    }

    removeLight(light: Light) {
        const index = this.lights.indexOf(light)
        if (index === -1) {
            throw new Error('Could not find Light')
        }
        this.lights.splice(index, 1)
    }
}

