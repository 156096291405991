const GlobalEventNamesI: Record<keyof GlobalEvents, null> = {
    /// DEFAULT
    Update: null,
    PhysicsUpdate: null,
    Render: null,
    OnKeyPressed: null,
    OnClick: null,
    Exit: null,

    /// CUSTOM
    GameOver: null,
}

export const GlobalEventNames = Object.keys(GlobalEventNamesI) as Array<keyof GlobalEvents>

export interface GlobalEvents {
    Update: undefined
    PhysicsUpdate: undefined
    Render: undefined
    Exit: undefined
    OnKeyPressed: { event: KeyboardEvent }
    OnClick: { event: MouseEvent }

    // CUSTOM EVENTS
    GameOver: undefined
}



const GameObjectEventNamesI: Record<keyof GameObjectEvents, null> = {
    Init: null,
    Destroy: null,

    // CUSTOM
}

export const GameObjectEventNames = Object.keys(GameObjectEventNamesI) as Array<keyof GameObjectEvents>;

export interface GameObjectEvents {
    // DEFAULT
    Init: undefined
    Destroy: undefined

    // CUSTOM
}


