import { mat3, mat4, quat, vec3 } from "gl-matrix"
import { warn } from "vue"
import { GameObject } from "./GameObject"
import { Manager } from "./Manager"

const tmpMat3 = mat3.create()

export class Transform {
    position = vec3.create()
    rotation = quat.create()
    scale = vec3.fromValues(1,1,1)
    private _matrix: mat4
    children?: Transform[]
    parent?: Transform
    gameObject: GameObject = <GameObject><unknown>null

    constructor(m: mat4) {
        this._matrix = m
    }

    get matrix(): mat4 {
        return this._matrix
    }
    set matrix(m: mat4) {
        this._matrix = m
    }

    getGlobalMatrix() {
        const tmp = mat4.create()
        const ret = mat4.create()
        let parent = this.parent
        while (parent !== undefined) {
            mat4.fromRotationTranslationScale(
                tmp,
                parent.rotation,
                parent.position,
                parent.scale
            )
            mat4.mul(ret, tmp, ret)
            parent = parent.parent
        }
        return ret
    }

    fromMat4(m: mat4) {
        vec3.set(this.position, m[12], m[13], m[14])
        vec3.set(
            this.scale,
            Math.sqrt(m[0]*m[0] + m[1]*m[1] + m[2]*m[2]),
            Math.sqrt(m[4]*m[4] + m[5]*m[5] + m[6]*m[6]),
            Math.sqrt(m[8]*m[8] + m[9]*m[9] + m[10]*m[10]),
        )
        quat.fromMat3(
            this.rotation,
            mat3.fromValues(
                m[0]  / this.scale[0],
                m[1]  / this.scale[0],
                m[2]  / this.scale[0],
                m[4]  / this.scale[1],
                m[5]  / this.scale[1],
                m[6]  / this.scale[1],
                m[8]  / this.scale[2],
                m[9]  / this.scale[2],
                m[10] / this.scale[2],
            )
        )
    }

    getGlobalPosition() {
        return this.parent ? vec3.transformMat4(vec3.create(), this.position, this.parent.getGlobalMatrix()) : vec3.clone(this.position)
    }

    getForward() {
        const v = vec3.fromValues(0, 0, -1)
        return vec3.transformQuat(v, v, this.rotation)
    }
    lookAt(eye: vec3, center: vec3, up: vec3) {
        mat4.targetTo(this._matrix, eye, center, up)
        mat3.fromMat4(tmpMat3, this._matrix)
        quat.fromMat3(this.rotation, tmpMat3)

        vec3.copy(this.position, eye)

    }
}

const INIT_POOL_SIZE = 16
export class TransformManagerClass extends Manager {
    private pool: Transform[]
    private notInUse: Transform[]
    private inUse: Transform[]

    private rootTransforms: Transform[]

    async Init() {
        this.pool = Array(INIT_POOL_SIZE).fill(null).map(_ => new Transform(mat4.create()))
        this.notInUse = this.pool.slice()
        this.inUse = []
        this.rootTransforms = []
    }

    Exit() {
        console.log(this.inUse)
        this.rootTransforms = []
        this.notInUse = this.notInUse.concat(this.inUse)
        this.inUse = []
    }

    get(): Transform {
        if (this.notInUse.length === 0) {
            console.info('Expanding Transform Pool')
            
            const app = Array(this.pool.length).fill(null).map(_ => new Transform(mat4.create()))
            this.pool = this.pool.concat(app)
            this.notInUse = this.notInUse.concat(app)

        }
        const t = this.notInUse.pop()!
        t.parent = undefined
        t.children = undefined
        this.inUse.push(t)

        vec3.set(t.position, 0, 0, 0)
        quat.set(t.rotation, 0, 0, 0, 1)
        vec3.set(t.scale, 1, 1, 1)

        return t
    }

    init(transform: Transform) {
        if (transform.parent === undefined) {
            this.rootTransforms.push(transform)
        }
    }

    free(transform: Transform) {
        const index = this.inUse.indexOf(transform)
        if (index === -1) {
            console.error('Transform not found')
            return
        }
        this.inUse.splice(index, 1)
        this.notInUse.push(transform)

        if (transform.parent === undefined) {
            const index = this.rootTransforms.indexOf(transform)
            if (index !== -1) {
                this.rootTransforms.splice(index, 1)
            }
        }

        transform.gameObject = <GameObject><unknown>undefined
        transform.children = undefined
        transform.parent = undefined
    }

    addChild(parent: Transform, child: Transform) {
        if (parent.children === undefined) {
            parent.children = [ child ]
        }
        else {
            parent.children.push(child)
        }

        if (child.parent) {
            const index = child.parent.children!.indexOf(child)
            if (index !== -1) {
                child.parent.children!.splice(index, 1)
            }
        }
        else {
            const index = this.rootTransforms.indexOf(child)
            if (index !== -1) {
                this.rootTransforms.splice(index, 1)
            }
        }

        child.parent = parent
    }
    
    removeChild(parent: Transform, child: Transform) {
        if (parent.children) {
            const index = parent.children.indexOf(child)
            if (index !== -1) {
                parent.children.splice(index, 1)
                child.parent = undefined
                this.rootTransforms.push(child)
            }
        }
    }

    private updateMatricesRecursive(transform: Transform, parentMatrix: mat4) {
        mat4.fromRotationTranslationScale(transform.matrix, transform.rotation, transform.position, transform.scale)
        mat4.mul(transform.matrix, parentMatrix, transform.matrix)
        if (transform.children !== undefined) {
            for (const child of transform.children) {
                this.updateMatricesRecursive(child, transform.matrix)
            }
        }
    }
    updateMatrices() {
        const rootMatrix = mat4.create()
        for (const t of this.rootTransforms) {
            this.updateMatricesRecursive(t, rootMatrix)
        }
    }
}

export const TransformManager = new TransformManagerClass()




