import { mat4, quat, vec3 } from "gl-matrix"

export type JointNode = {
    name: string,
    defaultRotation: quat,
    defaultTranslation: vec3,
    defaultScale: vec3,
    localForwardMatrix: mat4,
    globalForwardMatrix: mat4,
    matrix: mat4,
    parent: JointNode | null,
}

export type GLTFSkin = {
    jointStart: number,
    jointCount: number,
    uboBuffer: WebGLBuffer,
    buffer: Float32Array,
}

export type SkinsInfo = {
    skins: GLTFSkin[],

    inverseBindMatrices: mat4[],
    localForwardMatrices: mat4[],
    globalForwardMatrices: mat4[],
    jointMatrices: mat4[],
    jointParents: number[],

    jointNodes: JointNode[],
}

export class GLTFSkins {
    gl: WebGL2RenderingContext
    skins: GLTFSkin[]
    jointNodes: JointNode[]

    jointMatrices: mat4[]

    jointParents: number[]
    inverseBindMatrices: mat4[]
    localForwardMatrices: mat4[]
    globalForwardMatrices: mat4[]

    constructor(gl: WebGL2RenderingContext, skinsInfo: SkinsInfo) {
        this.gl = gl
        this.skins = skinsInfo.skins

        this.jointNodes = skinsInfo.jointNodes
        this.jointMatrices = skinsInfo.jointMatrices
        this.jointParents = skinsInfo.jointParents
        this.inverseBindMatrices = skinsInfo.inverseBindMatrices
        this.localForwardMatrices = skinsInfo.localForwardMatrices
        this.globalForwardMatrices = skinsInfo.globalForwardMatrices
    }

    resetJointForwardMatrices() {
        const jointNodes = this.jointNodes

        for (const jointNode of jointNodes) {
            mat4.fromRotationTranslationScale(
                jointNode.localForwardMatrix,
                jointNode.defaultRotation,
                jointNode.defaultTranslation,
                jointNode.defaultScale
            )
        }
    }

    updateJointForwardMatrices(animation: Float32Array[]) {
        const jointNodes = this.jointNodes

        for (let i = 0; i < jointNodes.length; i++) {
            const arrayIndex = i * 3
            mat4.fromRotationTranslationScale(
                jointNodes[i].localForwardMatrix,
                animation[arrayIndex + 0],
                animation[arrayIndex + 1],
                animation[arrayIndex + 2]
            )
        }
    }

    updateJoints(rootMatrix: mat4 = mat4.create()) {
        const gl = this.gl
        const skins = this.skins

        for (const skin of skins) {
            const numJoints = skin.jointCount
            for (let i = 0; i < numJoints; i++) {
                const index = skin.jointStart + i
                if (this.jointParents[index] === -1) {
                    mat4.mul(this.globalForwardMatrices[index], rootMatrix, this.localForwardMatrices[index])
                }
                else {
                    mat4.mul(this.globalForwardMatrices[index], this.globalForwardMatrices[this.jointParents[index]], this.localForwardMatrices[index])
                }
                mat4.mul(this.jointMatrices[index], this.globalForwardMatrices[index], this.inverseBindMatrices[index])
            }
            gl.bindBuffer(gl.UNIFORM_BUFFER, skin.uboBuffer)
            gl.bufferSubData(gl.UNIFORM_BUFFER, 0, skin.buffer)
            // gl.bufferData(gl.UNIFORM_BUFFER, skin.buffer, gl.DYNAMIC_DRAW)
        }
    }
    updateBuffers() {
        const gl = this.gl
        for (const skin of this.skins) {
            gl.bindBuffer(gl.UNIFORM_BUFFER, skin.uboBuffer)
            gl.bufferSubData(gl.UNIFORM_BUFFER, 0, skin.buffer)
        }
    }
}
