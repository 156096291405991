import { vec3 } from "gl-matrix"
import { Graphics } from "../Graphics"
import { BufferView } from "./BufferView"

const AttributeIDs = {
    Position: 0,
    Normal: 1,
    UV0: 2,
    Color: 3,

    Joints0: 4,
    Weights0: 5,
}

export class Geometry {
    mode: number
    attributes: Record<string, BufferView>
    indices?: BufferView
    count: number

    constructor() {
        this.mode = WebGL2RenderingContext['TRIANGLES']
        this.attributes = {}
        this.count = 0
    }

    static plane(width: number, height: number, width_subdivisions: number, height_subdivisions: number): Geometry {
        const ws1 = width_subdivisions + 1
        const ws2 = width_subdivisions + 2
        const hs1 = height_subdivisions + 1
        const hs2 = height_subdivisions + 2

        const positions = new Float32Array(3 * ws2 * hs2)
        const normals = new Float32Array(3 * ws2 * hs2)

        for (let i = 0; i < (ws2 * hs2); i++) {
            const x = i % ws2
            const y = i / ws2
            positions[i * 3 + 0] = (x / ws1 - 0.5) * width
            positions[i * 3 + 1] = (y / hs1 - 0.5) * height
            positions[i * 3 + 2] = 0

            normals[i * 3 + 0] = 0
            normals[i * 3 + 1] = 0
            normals[i * 3 + 2] = 1
        }

        const indices = new Uint32Array(6 * ws1 * hs1)

        for (let i = 0; i < ws1 * hs1; i++) {
            const x: number = i % ws1
            const y: number = i / ws1
            indices[i * 6 + 0] = ((x + 0) + (y + 0) * ws2)
            indices[i * 6 + 1] = ((x + 1) + (y + 0) * ws2)
            indices[i * 6 + 2] = ((x + 0) + (y + 1) * ws2)
            indices[i * 6 + 3] = ((x + 0) + (y + 1) * ws2)
            indices[i * 6 + 4] = ((x + 1) + (y + 0) * ws2)
            indices[i * 6 + 5] = ((x + 1) + (y + 1) * ws2)
        }

        const geometry = new Geometry()
        geometry.mode = WebGL2RenderingContext['TRIANGLES']

        let buffer: WebGLBuffer

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], positions, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.attributes['Position'] = new BufferView(buffer, 3, WebGL2RenderingContext['FLOAT'], 0, 0)

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], normals, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.attributes['Normal'] = new BufferView(buffer, 3, WebGL2RenderingContext['FLOAT'], 0, 0)

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ELEMENT_ARRAY_BUFFER'], indices, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.indices = new BufferView(buffer, 1, WebGL2RenderingContext['UNSIGNED_INT'], 0, 0)

        geometry.count = indices.length

        return geometry
    }

    static cuboid(sx: number, sy: number, sz: number): Geometry {
        const positions = new Float32Array([
            -sx, -sy,  sz,   sx, -sy,  sz,  -sx,  sy,  sz,
            -sx,  sy,  sz,   sx, -sy,  sz,   sx,  sy,  sz,

            -sx, -sy, -sz,  -sx,  sy, -sz,   sx, -sy, -sz,
             sx, -sy, -sz,  -sx,  sy, -sz,   sx,  sy, -sz,

             sx, -sy,  sz,   sx, -sy, -sz,   sx,  sy,  sz,
             sx,  sy,  sz,   sx, -sy, -sz,   sx,  sy, -sz,

            -sx, -sy,  sz,  -sx,  sy,  sz,  -sx, -sy, -sz,
            -sx, -sy, -sz,  -sx,  sy,  sz,  -sx,  sy, -sz,

            -sx,  sy,  sz,   sx,  sy,  sz,  -sx,  sy, -sz,
            -sx,  sy, -sz,   sx,  sy,  sz,   sx,  sy, -sz,

            -sx, -sy,  sz,  -sx, -sy, -sz,   sx, -sy,  sz,
             sx, -sy,  sz,  -sx, -sy, -sz,   sx, -sy, -sz,
        ])
        const normals = new Float32Array([
            0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,
            0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,
            1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,
            -1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,
            0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,0.0,1.0,0.0,
            0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,0.0,-1.0,0.0,
        ])

        const indices = new Uint32Array([
            0, 1, 2, 3, 4, 5,       // FRONT
            6, 7, 8, 9, 10, 11,     // BACK
            12, 13, 14, 15, 16, 17, // RIGHT
            18, 19, 20, 21, 22, 23, // LEFT
            24, 25, 26, 27, 28, 29, // TOP
            30, 31, 32, 33, 34, 35, // BOTTOM
        ])

        const geometry = new Geometry()
        geometry.mode = WebGL2RenderingContext['TRIANGLES']

        let buffer: WebGLBuffer

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], positions, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.attributes['Position'] = new BufferView(buffer, 3, WebGL2RenderingContext['FLOAT'], 0, 0)

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], normals, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.attributes['Normal'] = new BufferView(buffer, 3, WebGL2RenderingContext['FLOAT'], 0, 0)

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ELEMENT_ARRAY_BUFFER'], indices, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.indices = new BufferView(buffer, 1, WebGL2RenderingContext['UNSIGNED_INT'], 0, 0)

        geometry.count = indices.length

        return geometry
    }
    static cylinder(height: number, radius: number, discLod: number, heightSubdivisions?: number) {
        const n = discLod
        const h = heightSubdivisions ?? 0

        const geometry = new Geometry()
        const positions = new Float32Array((n + 1) * 6 + (n * h) * 3)
        for (let i = 0; i < n * (h + 2); i++) {
            const a = i % n
            const slice = Math.floor(i / n)

            const index = i * 3
            const angle = a * 2 * Math.PI / n

            positions[index + 0] = Math.cos(angle) * radius
            positions[index + 1] = Math.sin(angle) * radius
            positions[index + 2] = (slice / (h + 1) - 0.5) * height
        }
        const center = n * (h + 2)
        positions[center * 3 + 0] = 0
        positions[center * 3 + 1] = 0
        positions[center * 3 + 2] = -height / 2
        positions[(center + 1) * 3 + 0] = 0
        positions[(center + 1) * 3 + 1] = 0
        positions[(center + 1) * 3 + 2] = height / 2

        const indices = new Uint32Array(n * 6 + n*6 * (h + 1))
        for (let i = 0; i < n; i++) {
            indices[i * 3 + 0] = i
            indices[i * 3 + 2] = (i + 1) % n
            indices[i * 3 + 1] = center

            indices[(n + i) * 3 + 0] = (h + 1) * n + i
            indices[(n + i) * 3 + 1] = (h + 1) * n + ((i + 1) % n)
            indices[(n + i) * 3 + 2] = center + 1

            for (let j = 0; j <= h; j++) {
                indices[((2 + j * 2) * n + i) * 3 + 0] = j * n + i
                indices[((2 + j * 2) * n + i) * 3 + 1] = j * n + ((i + 1) % n)
                indices[((2 + j * 2) * n + i) * 3 + 2] = (j + 1) * n + ((i + 1) % n)

                indices[((2 + j * 2 + 1) * n + i) * 3 + 0] = (j + 1) * n + i
                indices[((2 + j * 2 + 1) * n + i) * 3 + 2] = (j + 1) * n + ((i + 1) % n)
                indices[((2 + j * 2 + 1) * n + i) * 3 + 1] = j * n + i
            }
        }

        const gl = Graphics.context
        geometry.attributes['Position'] = new BufferView(
            Graphics.createBuffer(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW),
            3, gl.FLOAT, 0, 0
        )
        geometry.indices = new BufferView(
            Graphics.createBuffer(gl.ELEMENT_ARRAY_BUFFER, indices, gl.STATIC_DRAW),
            1, gl.UNSIGNED_INT, 0, 0
        )

        geometry.mode = gl.TRIANGLES
        geometry.count = indices.length

        return geometry
    }

    static sphere(radius: number, subdivisions: number): Geometry {
        const p: number = (1 + Math.sqrt(5)) / 2
        const s: number = radius / Math.sqrt(p + 2)
        const t = p * s

        const positions: vec3[] = [
            [0.0, s, t],
            [0.0, -s, t],
            [0.0, s, -t],
            [0.0, -s, -t],
            [s, t, 0.0],
            [-s, t, 0.0],
            [s, -t, 0.0],
            [-s, -t, 0.0],
            [t, 0.0, s],
            [t, 0.0, -s],
            [-t, 0.0, s],
            [-t, 0.0, -s],
        ]

        const triangles = [
            0, 1, 8, 1, 0, 10, 3, 2, 9, 2, 3, 11,
            4, 5, 0, 5, 4, 2, 7, 6, 1, 6, 7, 3,
            8, 9, 4, 9, 8, 6, 11, 10, 5, 10, 11, 7,
            0, 8, 4, 0, 5, 10, 1, 6, 8, 1, 10, 7,
            2, 4, 9, 5, 2, 11, 6, 3, 9, 3, 7, 11,
        ]

        const links: Record<string, number> = {}

        const indices = new Uint32Array(20 * (subdivisions + 1) * (subdivisions + 1) * 3)
        let indicesIdx = 0

        const get_link = (positions: vec3[], key: string) => {
            const idx = links[key]
            if (idx !== undefined) {
                return idx
            }
            else {
                const idx = positions.length
                const m = key.match(/(\d+),(\d+)/)!
                const v0 = positions[parseInt(m[1])]
                const v1 = positions[parseInt(m[2])]
                for (let i = 0; i < subdivisions; i++) {
                    const v = [0, 0, 0] as vec3
                    vec3.lerp(v, v0, v1, (i + 1) / (subdivisions + 1))
                    vec3.normalize(v, v)
                    vec3.scale(v, v, radius)
                    positions.push(v)
                }
                links[key] = idx
                return idx
            }
        }
        for (let i = 0; i < triangles.length; i += 3) {
            const p0 = triangles[i + 0]
            const p1 = triangles[i + 1]
            const p2 = triangles[i + 2]

            const sign0 = p0 < p1
            const sign1 = p1 < p2
            const sign2 = p2 < p0
            const key0 = p0 < p1 ? `${p0},${p1}` : `${p1},${p0}`
            const key1 = p1 < p2 ? `${p1},${p2}` : `${p2},${p1}`
            const key2 = p2 < p0 ? `${p2},${p0}` : `${p0},${p2}`

            const link0 = get_link(positions, key0)
            const link1 = get_link(positions, key1)
            const link2 = get_link(positions, key2)

            const triangle = Array((subdivisions + 2) * (subdivisions + 3) / 2).fill(0)
            for (let line = 0; line < subdivisions + 2; line++) {
                if (line === 0) {
                    triangle[0] = p0
                }
                else if (line === subdivisions + 1) {
                    const start = line * (line + 1) / 2
                    triangle[start + 0] = p1
                    const link_start = sign1 ? link1 : link1 + subdivisions - 1
                    for (let i = 0; i < subdivisions; i++) {
                        triangle[start + 1 + i] = sign1 ? link_start + i : link_start - i
                    }
                    triangle[start + subdivisions + 1] = p2
                }
                else {
                    const start = line * (line + 1) / 2
                    const i0 =  sign0 ? link0 + line - 1 : link0 + subdivisions - line
                    const i1 = !sign2 ? link2 + line - 1 : link2 + subdivisions - line
                    triangle[start + 0]    = i0
                    triangle[start + line] = i1
                    const v0 = positions[i0]
                    const v1 = positions[i1]
                    for (let i = 0; i < line - 1; i++) {
                        triangle[start + 1 + i] = positions.length
                        const v = vec3.lerp([0, 0, 0], v0, v1, (i + 1) / line)
                        vec3.normalize(v, v)
                        vec3.scale(v, v, radius)
                        positions.push(v)
                    }
                }
            }
            for (let i = 0; i <= subdivisions; i++) {
                for (let j = 0; j <=i; j++) {
                    const line0 = i * (i + 1) / 2
                    const line1 = (i + 1) * (i + 2) / 2
                    indices[indicesIdx++] = triangle[line0 + j]
                    indices[indicesIdx++] = triangle[line1 + j]
                    indices[indicesIdx++] = triangle[line1 + j + 1]
                    if (j !== 0) {
                        indices[indicesIdx++] = triangle[line0 + j - 1]
                        indices[indicesIdx++] = triangle[line1 + j]
                        indices[indicesIdx++] = triangle[line0 + j]
                    }
                }
            }
        }

        const normals = new Float32Array((positions.map(v => vec3.normalize([0, 0, 0], v)) as number[][]).flat())

        const geometry = new Geometry()
        geometry.mode = WebGL2RenderingContext['TRIANGLES']

        let buffer: WebGLBuffer
        buffer = Graphics.createBuffer(
            WebGL2RenderingContext['ARRAY_BUFFER'],
            new Float32Array((positions as number[][]).flat()),
            WebGL2RenderingContext['STATIC_DRAW']
        )
        geometry.attributes['Position'] = new BufferView(buffer, 3, WebGL2RenderingContext['FLOAT'], 0, 0)

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ARRAY_BUFFER'], normals, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.attributes['Normal'] = new BufferView(buffer, 3, WebGL2RenderingContext['FLOAT'], 0, 0)

        buffer = Graphics.createBuffer(WebGL2RenderingContext['ELEMENT_ARRAY_BUFFER'], indices, WebGL2RenderingContext['STATIC_DRAW'])
        geometry.indices = new BufferView(buffer, 1, WebGL2RenderingContext['UNSIGNED_INT'], 0, 0)

        geometry.count = indices.length

        return geometry
    }

    static icosahedronInnerRadius(radius: number): Geometry {
        const p = (1 + Math.sqrt(5)) / 2
        const s = 1 / Math.sqrt(p + 2)
        const r = s * Math.sqrt(3) * (1 + p) / 3
        return Geometry.sphere(radius / r, 0)
    }
}




















