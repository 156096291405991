import { mat4, vec3 } from "gl-matrix"
import { Component } from "../../Component"
import { GameObject } from "../../GameObject"
import { FREE_INDEX0, Graphics } from "../../Graphics"
import { RenderableObject } from "../RenderableObject"
import { ShadowMap } from "../ShadowMap"
import { addSetUniform, setGbuffer } from "../utils"

export type SpotLightOptions = {
    color: vec3
    intensity: number
    radius: number
    halfAngle: number
    shadowMap?: boolean
}

export class SpotLightComponent extends Component {
    private gl: WebGL2RenderingContext
    private ro: RenderableObject

    radius: number
    halfAngle: number

    shadowMap: ShadowMap | null
    shadowMapTex: WebGLTexture | null = null

    constructor(parent: GameObject, options: SpotLightOptions) {
        super(parent)

        this.hasShadowMap = options.shadowMap ?? false

        this.gl = Graphics.context

        this.halfAngle = options.halfAngle
        this.radius = options.radius
        this.shadowMap = null

        const defines: Record<string, unknown> = {}

        if (options.shadowMap) {
            defines['USE_SHADOW_MAP'] = ''
        }
        const vShader = Graphics.shaders.getShader('basicVS')
        const fShader = Graphics.shaders.compileShader('spotLightFS', {
            type: 'fragment',
            defines,
        })

        const ro = new RenderableObject(this.gl, vShader, fShader, this.gl.TRIANGLE_STRIP, 4)
        this.ro = ro

        ro.useProgram()
        ro.addUniform('uView', 'mat4')
        ro.addUniform('uProjection', 'mat4')
        ro.addUniform('uOrthographic', 'bool')
        ro.addUniform('uLightPosition', 'vec3')
        addSetUniform(ro, 'uLightColor', 'vec3', options.color)
        addSetUniform(ro, 'uLightIntensity', 'float', options.intensity)
        addSetUniform(ro, 'uLightRadius', 'float', options.radius)
        ro.addUniform('uLightDirection', 'vec3')
        addSetUniform(ro, 'uLightAngle', 'float', options.halfAngle)

        if (options.shadowMap) {
            ro.addUniform('uShadowMapMat', 'mat4')
            addSetUniform(ro, 'uShadowMap', 'int', FREE_INDEX0)
        }

        setGbuffer(ro)
    }

    setShadowMap(shadowMap: ShadowMap | null) {
        this.shadowMap = shadowMap
        this.shadowMapTex = this.shadowMap?.texture ?? null
    }

    render(viewMatrix?: mat4) {
        const ro = this.ro
        ro.useProgram()
        ro.setUniform('uView', viewMatrix)
        ro.setUniform('uOrthographic', Graphics.camera?.data.type === 'orthographic')
        ro.setUniform('uLightPosition', this.parent.transform.position)
        ro.setUniform('uLightDirection', this.parent.transform.getForward())

        if (this.shadowMap) {
            const projection = mat4.perspective(mat4.create(), this.halfAngle * 2, 1, 0.01, this.radius)
            const matrix = mat4.invert(mat4.create(), this.parent.transform.matrix)
            mat4.mul(matrix, projection, matrix)
            ro.setUniform('uShadowMapMat', matrix)

            const gl = this.gl
            gl.activeTexture(gl.TEXTURE0 + FREE_INDEX0)
            gl.bindTexture(gl.TEXTURE_2D, this.shadowMapTex)
        }
        ro.render()
    }
}


