
//import ProjectDetails from './ProjectDetails.vue'
import { defineComponent } from 'vue';

import { main } from "@/js/index"

let engine: any

export default defineComponent({
    name: 'HomeView',
    components: {
//    ProjectDetails,
    },
    mounted() {
        engine = main()
    },
    unmounted() {
        if (engine && engine.stopped === false) {
            engine.destroy()
            engine = null
        }
    },
});
