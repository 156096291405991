import { Manager } from "./Manager";

export class TimeManager extends Manager {
  deltaTime = 0;
  time = 0;
  private then = 0;

  enterScene() {
    this.time = 0;
    this.then = 0;
    this.deltaTime = 0;
  }
  update(time: number) {
    this.deltaTime = Math.min(1 / 20, time - this.then);
    this.time += this.deltaTime;
    this.then = time;
  }
}

export const Time = new TimeManager()
