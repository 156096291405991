export function getDirectionalLightFS(shadowMaps?: number) {
    return `precision highp float;

uniform sampler2D uPosition;
uniform sampler2D uAlbedo;
uniform sampler2D uNormal;
uniform sampler2D uMaterial;

uniform mat4 uView;
uniform bool uOrthographic;

uniform vec3 uLightDirection;
uniform vec3 uLightColor;
uniform float uLightIntensity;

out vec4 oColor;

#ifdef USE_SHADOW_MAP

    #define SHADOW_BIAS 0.001

    uniform mat4 uShadowMapMats[SHADOW_MAPS_NUM];
    uniform sampler2D uShadowMaps[SHADOW_MAPS_NUM];

    float rand(vec2 co){
        return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
    }

    int sampleShadowMap(in sampler2D shadowMap, in vec4 v) {
        if (all(lessThan(abs(v.xy), vec2(1.)))) {
            float depthSample = texture(shadowMap, v.xy * .5 + .5).r;
            return (depthSample * 2. - 1. + SHADOW_BIAS < v.z) ? 0 : 1;
        }
        return 0;
    }

    int sample8ShadowMap(in sampler2D shadowMap, in vec4 v) {
        float d = 1. / 2048.;
        return (sampleShadowMap(shadowMap, v + vec4(d, 0, 0, 0)) + 
            sampleShadowMap(shadowMap, v + vec4(-d, 0, 0, 0)) + 
            sampleShadowMap(shadowMap, v + vec4(0, d, 0, 0)) + 
            sampleShadowMap(shadowMap, v + vec4(0, -d, 0, 0))) * 2 +

            (sampleShadowMap(shadowMap, v + vec4(d, d, 0, 0)) + 
            sampleShadowMap(shadowMap, v + vec4(-d, d, 0, 0)) + 
            sampleShadowMap(shadowMap, v + vec4(d, -d, 0, 0)) + 
            sampleShadowMap(shadowMap, v + vec4(-d, -d, 0, 0))); 
    }
#endif

#include <lightFS>

void main() {
    vec4 position4 = texelFetch(uPosition, ivec2(gl_FragCoord.xy), 0);
    if (position4.w == 0.)
        discard;
    vec3 position = position4.xyz;
    vec3 normal = texelFetch(uNormal, ivec2(gl_FragCoord.xy), 0).rgb;
    vec3 albedo = texelFetch(uAlbedo, ivec2(gl_FragCoord.xy), 0).xyz;
    vec4 material = texelFetch(uMaterial, ivec2(gl_FragCoord.xy), 0);

    vec3 lightDir = mat3(uView) * -uLightDirection;

    vec3 viewDir = uOrthographic ? vec3(0, 0, 1) : -position;

    // vec3 geometryNormal = normal;
    // vec3 dxy = max( abs( dFdx( geometryNormal ) ), abs( dFdy( geometryNormal ) ) );
    // float geometryRoughness = max( max( dxy.x, dxy.y ), dxy.z );
    float geometryRoughness = material.b;

    vec3 L = normalize(lightDir);
    vec3 V = normalize(viewDir);
    vec3 N = normal;
    vec3 H = normalize(L+V);

    float NdH = max(0.0, dot(N, H));
    float VdH = max(0.0, dot(V, H));
    float NdV = max(0.0, dot(N, V));
    float NdL = max(0.0, dot(N, L));

    vec3 I = uLightColor * uLightIntensity * NdL;

#ifdef USE_SHADOW_MAP
    vec3 p = (inverse(uView) * vec4(position, 1)).xyz + N * 0.02;
    int s = 1;
    bool done = false;
${
    shadowMaps !== undefined ?
        Array(shadowMaps).fill(null).map((_, i) => `
    if (done == false) {
        vec4 v = uShadowMapMats[${i}] * vec4(p, 1.);
        v.xyz /= v.w;

        if (all(lessThan(abs(v.xyz), vec3(1.)))) {
            float depthSample = texture(uShadowMaps[${i}], v.xy * .5 + .5).r;
            s = (v.z <= (depthSample * 2. - 1.) + SHADOW_BIAS) ? 1 : 0;
            done = true;
        }

    }
`).join('') : ''
}
    I *= float(s);
#endif

    float metallic = material.g;
    float roughness = material.r;

    roughness = max(roughness, 0.0525);
    roughness += geometryRoughness;
    roughness = min(roughness, 1.);
    vec3 F0 = mix(vec3(0.04), albedo, metallic);

    vec3 diffuse = albedo * (1. - metallic);

    vec3 col = max(vec3(0), PI * I * (BRDF(diffuse) + CookTorrance(NdH, VdH, NdV, NdL, roughness, F0)));

    oColor = vec4(col, 1);
}
`
}
