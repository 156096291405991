import { mat4, vec3 } from 'gl-matrix';
import { GlobalEvents, GameObjectEvents } from './EventList';
import { GameObject } from './GameObject';
import { Manager } from './Manager';
import { InstancedMesh, Mesh } from './graphics/Mesh';
import { LightManager } from './graphics/Light';
import { SSAOPass } from './graphics/AOPass';
import { createTextureAndBindToFramebuffer, fetchShaderSrc, framebufferStatus } from './graphics/utils';
import { Shaders } from './graphics/Shaders';
import { CameraComponent } from './graphics/Camera';
import { Transform } from './Transform';
import { Component } from './Component';
import { BloomPass } from './graphics/BloomPass';
import { Material } from './graphics/Material';
import { Geometry } from './graphics/Geometry';

export const POSITION_INDEX = 0
export const ALBEDO_INDEX = 1
export const NORMAL_INDEX = 2
export const EMISSION_INDEX = 3
export const MATERIAL_INDEX = 4

export const AO_INDEX = 5
export const DEPTH_INDEX = 6
export const LIGHTING_INDEX = 7

export const FREE_INDEX0 = 8

export class MeshComponent extends Component {
    transform: Transform
    mesh: Mesh | InstancedMesh | GLTFMesh

    constructor(parent: GameObject, mesh: Mesh | GLTFMesh | InstancedMesh) {
        super(parent)

        this.transform = this.parent.transform

        this.mesh = mesh
    }

    render(viewMatrix: mat4, projectionMatrix: mat4) {
        this.mesh.render(this.transform.matrix, viewMatrix, projectionMatrix)
    }
}

export interface GraphicObject {
    render: (viewMatrix: mat4, projectionMatrix: mat4) => void
}

type GraphicsManagerTextures = {
    position: WebGLTexture
    normal: WebGLTexture
    albedo: WebGLTexture
    emission: WebGLTexture
    material: WebGLTexture
    depth: WebGLTexture
}

export class GraphicsManager extends Manager {
    context: WebGL2RenderingContext

    private objects: GraphicObject[]

    shaders: Shaders

    private frameBuffer: WebGLFramebuffer
    private textures: GraphicsManagerTextures

    private postProcessTex0: WebGLTexture
    private postProcessTex1: WebGLTexture

    lightManager: LightManager

    private outputProgram: WebGLProgram

    private ssaoPass: SSAOPass

    private bloomPass: BloomPass

    private GLTFModels: Record<string, GLTFMesh>
    private GLTFRawAnimations: Record<string, GLTFRawAnimation[]>

    camera: CameraComponent | null

    canvasWidth = 800
    canvasHeight = 600

    private gameWindow: HTMLDivElement

    async Init() {
        this.objects = []
        this.GLTFModels = {}
        this.GLTFRawAnimations = {}
        this.camera = null

        const gameWindow = document.querySelector('.game-window') as HTMLDivElement
        gameWindow.style.setProperty('--width', this.canvasWidth + 'px')
        gameWindow.style.setProperty('--height', this.canvasHeight + 'px')
        this.gameWindow = gameWindow

        const canvas = document.createElement('canvas')
        canvas.width = this.canvasWidth
        canvas.height = this.canvasHeight
        gameWindow.appendChild(canvas)

        const gl = canvas.getContext('webgl2', { antialias: false }) as WebGL2RenderingContext

        this.context = gl

        const a = vec3.fromValues(1, 0, 0)
        const b = vec3.fromValues(0, 1, 0)
        console.log(vec3.cross(vec3.create(), a, b))

        gl.getExtension("EXT_color_buffer_float")
        gl.getExtension("EXT_float_blend")
        gl.getExtension("OES_texture_float_linear")

        gl.enable(gl.CULL_FACE)

        gl.clearColor(0, 0, 0, 1)

        this.frameBuffer = gl.createFramebuffer() as WebGLFramebuffer
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.frameBuffer)

        const depthTexture = gl.createTexture() as WebGLTexture
        gl.bindTexture(gl.TEXTURE_2D, depthTexture)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.DEPTH_COMPONENT24, gl.canvas.width, gl.canvas.height, 0, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, null)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, depthTexture, 0)

        this.textures = {
            position: createTextureAndBindToFramebuffer(gl, POSITION_INDEX),
            albedo: createTextureAndBindToFramebuffer(gl, ALBEDO_INDEX),
            normal: createTextureAndBindToFramebuffer(gl, NORMAL_INDEX),
            material: createTextureAndBindToFramebuffer(gl, MATERIAL_INDEX),
            emission: createTextureAndBindToFramebuffer(gl, EMISSION_INDEX),
            depth: depthTexture,
        }


        framebufferStatus(gl)

        gl.bindFramebuffer(gl.FRAMEBUFFER, null)

        this.postProcessTex0 = gl.createTexture() as WebGLTexture
        gl.bindTexture(gl.TEXTURE_2D, this.postProcessTex0)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA32F, gl.canvas.width, gl.canvas.height, 0, gl.RGBA, gl.FLOAT, null)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)

        this.postProcessTex1 = gl.createTexture() as WebGLTexture
        gl.bindTexture(gl.TEXTURE_2D, this.postProcessTex1)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA32F, gl.canvas.width, gl.canvas.height, 0, gl.RGBA, gl.FLOAT, null)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)

        this.shaders = new Shaders(gl)

        await this.loadShaderSrcs()

        this.ssaoPass = new SSAOPass()

        this.outputProgram = gl.createProgram() as WebGLProgram
        gl.attachShader(this.outputProgram, this.shaders.compileShader('basicVS', {type: 'vertex'}))
        gl.attachShader(this.outputProgram, this.shaders.compileShader('outputFS', {type: 'fragment'}))
        gl.linkProgram(this.outputProgram)
        if (!gl.getProgramParameter(this.outputProgram, gl.LINK_STATUS)) {
            throw new Error('Could not link program: ' + gl.getProgramInfoLog(this.outputProgram))
        }
        gl.useProgram(this.outputProgram)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uPosition'), POSITION_INDEX)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uAlbedo'), ALBEDO_INDEX)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uNormal'), NORMAL_INDEX)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uMaterial'), MATERIAL_INDEX)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uEmission'), EMISSION_INDEX)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uAO'), AO_INDEX)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uColor'), LIGHTING_INDEX)
        gl.uniform1i(gl.getUniformLocation(this.outputProgram, 'uDepth'), DEPTH_INDEX)

        this.lightManager = new LightManager()
        this.lightManager.init()

        this.bloomPass = new BloomPass(gl, gl.canvas.width, gl.canvas.height)
    }

    async loadShaderSrcs() {
        const shaderFilenames = [
            'vertexShader',
            'fragmentShader',
            'lightFS',
            'basicVS',
            'pointLightVS',
            'pointLightFS',
            'emissionFS',
            'outputFS',
            'aoFS',
            'spotLightFS',
            'ambientFS',
        ]
        for (const n of shaderFilenames) {
            this.shaders.loadAsync(n, n)
        }
        await this.shaders.waitForAll()

        this.shaders.compileShader('basicVS', {type: 'vertex', store: {id: 'basicVS'}})
        this.shaders.compileShader('pointLightVS', {type: 'vertex', store: {id: 'pointLightVS'}})
        this.shaders.compileShader('pointLightFS', {type: 'fragment', store: {id: 'pointLightFS'}})
        this.shaders.compileShader('spotLightFS', {type: 'fragment', store: {id: 'spotLightFS'}})
        this.shaders.compileShader('ambientFS', {type: 'fragment', store: {id: 'ambientFS'}})
    }

    Exit() {
        this.lightManager.reset()
    }

    Destructor() {
        this.objects = []
        this.shaders = <Shaders><unknown>null
        this.lightManager = <LightManager><unknown>null

        this.GLTFModels = {}
        this.GLTFRawAnimations = {}

        this.gameWindow.removeChild(this.context.canvas)
    }

    /*
    createMesh(geometry: Geometry, material: Material): Mesh {
        return new Mesh(geometry, material)
    }
    createInstancedMesh(geometry: Geometry, material: Material, localTransforms: mat4[] | WebGLBuffer | null, count: number): InstancedMesh {
        return new InstancedMesh(geometry, material, localTransforms, count)
    }
    */
    createBuffer(target: number, data: any, usage: number): WebGLBuffer {
        const gl = this.context
        const buffer = gl.createBuffer()
        gl.bindBuffer(target, buffer)
        gl.bufferData(target, data, usage)

        return buffer as WebGLBuffer
    }

    addMeshComponent(parent: GameObject, mesh: Mesh | InstancedMesh | GLTFMesh) {
        const component = new MeshComponent(parent, mesh)
        parent.addComponent(component)

        GameObject.createListener(parent, 'Init', (() => {
            this.addObject(component)
        }).bind(this))
        GameObject.createListener(parent, 'Destroy', (() => {
            this.removeObject(component)
        }).bind(this))
    }

    addObject(obj: GraphicObject) {
        this.objects.push(obj)
    }

    removeObject(obj: GraphicObject) {
        const index = this.objects.indexOf(obj)
        if (index === -1) {
            throw new Error('Object not found in Graphics.objects')
        }
        this.objects.splice(index, 1)
    }


    async loadGLTF(id: string, filename: string, instanced?: InstancedOptions) {
        const loader = new GLTFLoader()
        const ret = await loader.load(this.context, filename, instanced)
        if (ret.mesh !== undefined) {
            this.GLTFModels[id] = ret.mesh
        }
        if (ret.rawAnimations !== undefined) {
            this.GLTFRawAnimations[id] = ret.rawAnimations
        }
    }
    getGLTFModel(id: string): GLTFMesh | undefined {
        return this.GLTFModels[id]
    }
    getGLTFAnimations(id: string): GLTFRawAnimation[] | undefined {
        return this.GLTFRawAnimations[id]
    }

    Render() {
        const gl = this.context

        if (this.camera === null) {
            return
        }

        this.lightManager.renderShadowMaps(this.objects)

        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

        const projectionMatrix = this.camera.projection
        const viewMatrix = this.camera.getVueMatrix()

        this.objectsPass(viewMatrix, projectionMatrix)

        this.bindGBuffer()
        
        this.ssaoPass.pass(projectionMatrix)
        gl.activeTexture(gl.TEXTURE0 + AO_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.ssaoPass.output)

        this.lightManager.render(viewMatrix, projectionMatrix)

        gl.activeTexture(gl.TEXTURE0 + LIGHTING_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.lightManager.lightTex)

        this.bloomPass.pass(this.lightManager.lightTex, this.lightManager.lightTex)

        gl.activeTexture(gl.TEXTURE0 + LIGHTING_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.lightManager.lightTex)

        gl.bindFramebuffer(gl.FRAMEBUFFER, null)
        gl.clearColor(0, 0, 0, 1)
        gl.clear(gl.COLOR_BUFFER_BIT)

        gl.useProgram(this.outputProgram)

        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
    }

    objectsPass(viewMatrix: mat4, projectionMatrix: mat4) {
        const gl = this.context

        gl.bindFramebuffer(gl.FRAMEBUFFER, this.frameBuffer)

        gl.enable(gl.DEPTH_TEST)
        gl.drawBuffers([
            gl.COLOR_ATTACHMENT0 + 0,
            gl.COLOR_ATTACHMENT0 + 1,
            gl.COLOR_ATTACHMENT0 + 2,
            gl.COLOR_ATTACHMENT0 + 3,
            gl.COLOR_ATTACHMENT0 + 4,
            gl.COLOR_ATTACHMENT0 + 5,
        ])

        gl.clearColor(0, 0, 0, 0)
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)

        for (const object of this.objects) {
            object.render(viewMatrix, projectionMatrix)
        }

        gl.disable(gl.DEPTH_TEST)
    }

    bindGBuffer() {
        const gl = this.context
        gl.activeTexture(gl.TEXTURE0 + DEPTH_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.textures.depth)

        gl.activeTexture(gl.TEXTURE0 + POSITION_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.textures.position)
        
        gl.activeTexture(gl.TEXTURE0 + ALBEDO_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.textures.albedo)

        gl.activeTexture(gl.TEXTURE0 + NORMAL_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.textures.normal)

        gl.activeTexture(gl.TEXTURE0 + MATERIAL_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.textures.material)

        gl.activeTexture(gl.TEXTURE0 + EMISSION_INDEX)
        gl.bindTexture(gl.TEXTURE_2D, this.textures.emission)
    }
}


export const Graphics = new GraphicsManager()






import { GLTFLoader, GLTFMesh, GLTFRawAnimation, InstancedOptions } from './graphics/GLTFLoader';import { Engine } from '../Engine';

