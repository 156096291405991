import { mat4, vec3 } from "gl-matrix"
import { Component } from "../../Component"
import { GameObject } from "../../GameObject"
import { Graphics } from "../../Graphics"
import { BufferView } from "../BufferView"
import { Geometry } from "../Geometry"
import { RenderableObject } from "../RenderableObject"
import { setGbuffer } from "../utils"

type PointLightInput<T> = { type: 'instanced', data: BufferView } | { type: 'uniform', data: T }

type Options = {
    type: 'instanced',
    count: number,
    positions: BufferView,
    intensity: PointLightInput<number>
    color: PointLightInput<Float32Array>
    radius: PointLightInput<number>
}

type StandardOptions = {
    type: 'standard',
    intensity: number,
    color: vec3,
    radius: number,
}

export type PointLightOptions = Options | StandardOptions

export class PointLightComponent extends Component {
    private gl: WebGL2RenderingContext

    private geometry: Geometry

    private ro: RenderableObject

    private radius?: Float32Array
    private intensity?: Float32Array
    private color?: Float32Array

    constructor(parent: GameObject, options: PointLightOptions) {
        super(parent)

        const gl = Graphics.context
        this.gl = gl

        const defines: Record<string, any> = {}
        if (options.type === 'instanced') { 
            defines.INSTANCED = ''
            if (options.color.type !== 'uniform') {
                defines.INSTANCED_COLOR = ''
            }
            if (options.radius.type !== 'uniform') {
                defines.INSTANCED_RADIUS = ''
            }
            if (options.intensity.type !== 'uniform') {
                defines.INSTANCED_INTENSITY = ''
            }
        }
        this.color = options.type === 'standard' || options.color.type === 'uniform' ? new Float32Array(3) : undefined
        this.intensity = options.type === 'standard' || options.intensity.type === 'uniform' ? new Float32Array(1) : undefined
        this.radius = options.type === 'standard' || options.radius.type === 'uniform' ? new Float32Array(1) : undefined

        const vShader = Graphics.shaders.compileShader('pointLightVS', { type: 'vertex', defines })
        const fShader = Graphics.shaders.getShader('pointLightFS')

        this.geometry = Geometry.icosahedronInnerRadius(1)

        const ro = new RenderableObject(gl, vShader, fShader, this.geometry.mode, this.geometry.count)
        this.ro = ro
        ro.setIndices(this.geometry.indices!)

        ro.addUniform('uLightPosition', 'vec3')
        ro.addUniform('uView', 'mat4')
        ro.addUniform('uProjection', 'mat4')
        ro.addUniform('uOrthographic', 'bool')
        ro.setAttrib(ro.getAttribLocation('aPosition'), this.geometry.attributes['Position'], 0, 0)

        ro.useProgram()

        if (options.type === 'instanced') {
            ro.instanceCount = options.count

            ro.setAttrib(ro.getAttribLocation('aLightLocalPosition'), options.positions, 0, 1)

            if (options.intensity.type === 'uniform') {
                ro.addUniform('lightIntensity', 'float')
                this.setIntensity(options.intensity.data)
            }
            else {
                ro.setAttrib(ro.getAttribLocation('lightIntensity'), options.intensity.data, 0, 1)
            }
            if (options.radius.type === 'uniform') {
                ro.addUniform('lightRadius', 'float')
                this.setRadius(options.radius.data)
            }
            else {
                ro.setAttrib(ro.getAttribLocation('lightRadius'), options.radius.data, 0, 1)
            }
            if (options.color.type === 'uniform') {
                ro.addUniform('lightColor', 'vec3')
                this.setColor(options.color.data)
            }
            else {
                ro.setAttrib(ro.getAttribLocation('lightColor'), options.color.data, 0, 1)
            }
        }
        else {
            ro.addUniform('lightColor', 'vec3')
            ro.addUniform('lightIntensity', 'float')
            ro.addUniform('lightRadius', 'float')

            this.setColor(options.color)
            this.setRadius(options.radius)
            this.setIntensity(options.intensity)
        }

        setGbuffer(ro)
    }

    setRadius(v: number) {
        this.radius![0] = v
        this.ro.setUniform('lightRadius', this.radius)
    }

    setIntensity(v: number) {
        this.intensity![0] = v
        this.ro.setUniform('lightIntensity', this.intensity)
    }

    setColor(c: vec3) {
        this.color!.set(c)
        this.ro.setUniform('lightColor', this.color)
    }

    getRadius() {
        return this.radius![0]
    }

    getIntensity() {
        return this.intensity![0]
    }

    getColor() {
        return this.color
    }

    render(viewMatrix?: mat4, projectionMatrix?: mat4) {
        const gl = this.gl
        const ro = this.ro

        gl.cullFace(gl.FRONT)
        ro.useProgram()
        ro.setUniform('uView', viewMatrix)
        ro.setUniform('uProjection', projectionMatrix)
        ro.setUniform('uOrthographic', Graphics.camera?.data.type === 'orthographic')
        ro.setUniform('uLightPosition', this.parent.transform.position)
        ro.render()
        gl.cullFace(gl.BACK)
    }
}

