import { mat3, mat4, quat, vec2, vec3 } from "gl-matrix";

import { Enemy } from "./game/Enemy"
import { PHYSICS_GROUPS } from "./game/Constants";
import { Obstacle } from "./Obstacle";
import { GameObject } from "./engine/GameObject";
import { Graphics, MeshComponent } from "./engine/Graphics";
import { Geometry } from "./engine/graphics/Geometry";
import { Engine } from "./Engine";
import { Material } from "./engine/graphics/Material";
import { GLTFMesh } from "./engine/graphics/GLTFLoader";
import { Physics2D, Physics3D, RigidBody2DComponent, Sensor2DComponent } from "./engine/Physics";
import { Particles } from "./engine/graphics/Particles";
import { Transform } from "./engine/Transform";
import { CameraComponent } from "./engine/graphics/Camera";
import { AnimationBlender, Animator } from "./engine/graphics/GLTF/Animator";
import { Inputs } from "./engine/Inputs";
import { Time } from "./engine/Time";
import { Scene } from "./engine/Scene";
import { Instance } from "./engine/Instance";
import { HUD } from "./engine/HUD";
import { InstancedMesh, Mesh } from "./engine/graphics/Mesh";
import { SnakeScene } from "./game/Snake";
import { SnakeMenuScene } from "./game/SnakeMenu";
import { BufferView } from "./engine/graphics/BufferView";

class Box extends GameObject {
    constructor(x: number, y: number, z: number) {
        super()

        const material = Material.Default()
            .useColor(vec3.fromValues(1,1,1))
            .useNormals()
        Graphics.addMeshComponent(this, new Mesh(Geometry.cuboid(1,1,1), material))

        vec3.set(this.transform.position, x, y, z)
    }
}

class Ambient extends GameObject {
    constructor() {
        super()

        Graphics.lightManager.addAmbientLightComponent(this, vec3.fromValues(0.7,0.85,1), 0.3)
    }
}

class Light extends GameObject {
    constructor(color?: vec3) {
        super()

        Graphics.lightManager.addPointLightComponent(this, {
            type: 'standard',
            color: color ?? vec3.fromValues(1,1,1),
            intensity: 10,
            radius: 30,
        })
    }
}

class TestGO extends GameObject {
    constructor() {
        super()
        
        Graphics.addMeshComponent(this, Graphics.getGLTFModel('model') as GLTFMesh)
    }

    Update() {
        quat.fromEuler(this.transform.rotation, 0, 0, 0)
    }
}

class RO extends GameObject {
    //helper: Helper

    constructor() {
        super()
        
        const radius = 1
        const intensity = 0
        const emission = 0

        const geometry = Geometry.sphere(radius, 3)

        const material = Material.Default()
            .useNormals()
            .setMetalness(0.5)
            .setRoughness(0.5)
            .useColor(vec3.fromValues(1,1,1))
            .useEmission(vec3.fromValues(emission, emission, emission))


        Graphics.lightManager.addPointLightComponent(this, {
            type: 'standard',
            color: vec3.fromValues(1,1,1),
            intensity,
            radius: 30,
        })

        const mesh = new Mesh(geometry, material)
        Graphics.addMeshComponent(this, mesh)

        vec3.set(this.transform.position, 0, 0, 0)

        //this.helper = new Helper(Engine, this.transform, this.getComponent(PointLightComponent))

        const pm = Physics3D
        pm.addRigidBodyComponent(this,
            pm.RAPIER.ColliderDesc.ball(1),
            pm.RAPIER.RigidBodyDesc.dynamic()
        )
    }
    Init() {
        //Graphics.addObject(this.helper)
    }
    Destroy() {
        //Graphics.removeObject(this.helper)
    }
}


class GeoObject extends GameObject {
    part: Particles

    constructor() {
        super()
        
        const geometry = Geometry.sphere(0.03, 1)

        const material = Material.Default()
            .useNormals()
            .setMetalness(0)
            .setRoughness(0.9)
            .useColor(vec3.fromValues(1,1,1))
            .useEmission(vec3.fromValues(1, 5, 2))

        const count = 400
        const lCount = count

        const color = new Float32Array(Array(lCount).fill(null).map((_) => {
            const n = Math.random() * 3
            const idx = Math.floor(n)
            const f = n - idx
            const ret = [0, 0, 0]
            ret[idx] = 1 - f
            ret[(idx + 1) % 3] = f
            return ret
        }).flat())

        const gl = Graphics.context
        const intensities = new BufferView(
            Graphics.createBuffer(gl.ARRAY_BUFFER, new Float32Array(Array(lCount).fill(0.03)), gl.DYNAMIC_COPY),
            1, gl.FLOAT, 0, 0
        )
        const positions = new BufferView(
            Graphics.createBuffer(gl.ARRAY_BUFFER, new Float32Array(Array(lCount * 3).fill(0)), gl.DYNAMIC_COPY),
            3, gl.FLOAT, 0, 0
        )
        Graphics.lightManager.addPointLightComponent(this, {
            type: 'instanced',
            positions: positions,
            color: { type: 'uniform', data: new Float32Array([0.2, 1, 0.4]) },
            //color: { type: 'instanced', data: color },
            intensity: { type: 'instanced', data: intensities },
            radius: { type: 'uniform', data: 2},
            count: lCount,
        })

        const mesh = new InstancedMesh(geometry, material, null, count)

        this.part = new Particles(
            mesh.localTransformsBuffer,
            positions,
            intensities,
            count
        )

        Graphics.addMeshComponent(this, mesh)

        vec3.set(this.transform.position, 0.5, 0, 1)

        const pm = Physics3D
        pm.addRigidBodyComponent(
            this,
            pm.RAPIER.ColliderDesc.ball(0.5),
            pm.RAPIER.RigidBodyDesc.dynamic()
        )
    }
    Update() {
        this.part.render()
    }
}

class TO extends GameObject {
    constructor() {
        super()
        
        const geometry = Geometry.sphere(0.1, 3)

        const material = Material.Default()
            .useNormals()
            .setMetalness(0)
            .setRoughness(0.9)
            .useColor(vec3.fromValues(1,1,1))
            .useEmission(vec3.fromValues(2,2,2))

        Graphics.addMeshComponent(this, new Mesh(geometry, material))

        // Graphics.lightManager.addPointLightComponent(this, vec3.fromValues(1,1,1), 5, 20)
    }
}

class Floor extends GameObject {
    constructor() {
        super()
        
        Graphics.addMeshComponent(this, Graphics.getGLTFModel('terrain') as GLTFMesh)

        vec3.set(this.transform.position, 0, -2, 0)

        const pm = Physics3D
        pm.addRigidBodyComponent(
            this,
            pm.RAPIER.ColliderDesc.cuboid(5, 0.1, 5),
            pm.RAPIER.RigidBodyDesc.fixed(),
        )
    }
}

class Camera extends GameObject {
    target: Transform
    offset = vec3.fromValues(0, 2 + 5, -5)

    constructor(target: Transform) {
        super()

        this.target = target

        this.addComponent(new CameraComponent(this, {
            type: 'perspective',
            fovy: 60 / 180 * Math.PI,
            aspect: Graphics.canvasWidth / Graphics.canvasHeight,
            near: 0.01,
            far: 1000
        }))
        quat.fromEuler(this.transform.rotation, -45, 180, 0)
    }

    Init() {
        Graphics.camera = this.getComponent(CameraComponent)
    }

    Update() {
        const end = vec3.add(vec3.create(), this.target.position, this.offset)
        vec3.lerp(this.transform.position, this.transform.position, end, 0.1)
    }
}

class ZBot extends GameObject {
    model: GLTFMesh
    animator: Animator

    blender: AnimationBlender
    blenderRunning: AnimationBlender

    rightHand: GameObject | null = null

    dir = vec2.create()

    state: 'idle' | 'moving' = 'idle'

    constructor() {
        super()
        
        this.model = Graphics.getGLTFModel('x_bot') as GLTFMesh

        Graphics.addMeshComponent(this, this.model)
        this.animator = this.addComponent(new Animator(
            this,
            this.model,
            [
                ...Graphics.getGLTFAnimations('x_bot')!,
                ...Graphics.getGLTFAnimations('x_bot_ss')!,
            ],
            'mixamorig:Hips',
            vec3.fromValues(1, 1, 0)
        ))

        this.animator.addClip(this.animator.animations.findIndex(animation => animation.name === 'idle_0'))
        this.animator.addClip(this.animator.animations.findIndex(animation => animation.name === 'run'))
        this.animator.addClip(this.animator.animations.findIndex(animation => animation.name === 'strafe_run_left'))
        this.animator.addClip(this.animator.animations.findIndex(animation => animation.name === 'strafe_run_right'))

        this.blenderRunning = new AnimationBlender([this.animator.clips[1], this.animator.clips[2]], true)
        this.blender = new AnimationBlender([this.animator.clips[0], this.blenderRunning], true)

        const speed = 1.
        this.animator.clips[1].speed = speed
        //this.animator.clips[2].speed = speed * this.animator.clips[2].animation.duration / this.animator.clips[1].animation.duration
        //this.animator.clips[3].speed = speed * this.animator.clips[3].animation.duration / this.animator.clips[1].animation.duration

        vec3.set(this.transform.position, 0, -2, 0)

        this.addChild(new Test(this))
    }

    Init() {
        this.animator.play([0, 1, 2, 3])
        this.dir[0] = 0
        this.dir[1] = 0
        this.state = 'idle'
    }

    Destroy() {
        this.animator = <Animator><unknown>null
    }

    Update() {
        const input = vec2.create()

        if (Inputs.get('z')) {
            input[1] += 1
        }
        
        if (Inputs.get('s')) {
            input[1] -= 1
        }

        if (Inputs.get('q')) {
            input[0] -= 1
        }

        if (Inputs.get('d')) {
            input[0] += 1
        }

        if (input[0] !== 0 || input[1] !== 0) {
            vec2.normalize(input, input)
        }

        vec2.lerp(this.dir, this.dir, input, Time.deltaTime * 10)

        if (vec2.length(this.dir) < 0.01) {
            if (this.state !== 'idle') {
                this.state = 'idle'
            }
            this.blender.factor = 0
            this.animator.update(this.blender, Time.deltaTime)
        }
        else {
            if (this.state !== 'moving') {
                this.state = 'moving'
            }
            const forward = this.transform.getForward()
            const forward2d = vec2.fromValues(forward[0], -forward[2])
            vec2.normalize(forward2d, forward2d)
            vec2.set(
                forward2d,
                forward2d[0] * this.dir[0] + forward2d[1] * this.dir[1],
                forward2d[0] * this.dir[1] - forward2d[1] * this.dir[0]
            )
            const angle = Math.atan2(forward2d[1], forward2d[0])

            const rot = angle * Math.min(1, Time.deltaTime) * 0
            quat.rotateY(this.transform.rotation, this.transform.rotation, rot)
            const newAngle = angle - rot

            this.blenderRunning.inputs[1] = this.animator.clips[newAngle < 0 ? 3 : 2]

            this.blenderRunning.factor = Math.abs(newAngle / Math.PI * 2.)
            this.blender.factor = Math.sqrt((vec2.length(this.dir) - 0.01) / 0.99)
            this.animator.update(this.blender, Time.deltaTime)
        }

        if (this.rightHand !== null) {
            const animator = this.animator
            const mat = this.model.meshInstances[0].localTransform
            const jointIndex = animator.skins.jointNodes.findIndex(n => n.name === 'mixamorig:RightHand')
            const joint = animator.skins.globalForwardMatrices[jointIndex]
            const inverse = mat4.clone(animator.skins.inverseBindMatrices[jointIndex])
            inverse[12] = 35
            inverse[13] = 7
            inverse[14] = 3
            const tot = mat4.mul(mat4.create(), mat, joint)
            mat4.mul(tot, tot, inverse)
            this.rightHand.transform.fromMat4(tot)
        }

        const mat = mat3.fromMat4(mat3.create(), this.model.meshInstances[0].localTransform)
        const disp = vec3.transformMat3(vec3.create(), this.animator.rootMotionDisplacement!, mat)
        vec3.transformQuat(disp, disp, this.transform.rotation)
        vec3.add(this.transform.position, this.transform.position, disp)
    }
}

class Test extends GameObject {
    target: ZBot

    constructor(target: ZBot) {
        super()

        this.target = target
        const s = 0.02
        Graphics.addMeshComponent(
            this, new Mesh(
                Geometry.cuboid(s, s, 0.5),
                Material.Default()
                .useColor(vec3.fromValues(1, 0, 1))
                .useEmission(vec3.fromValues(2, 0, 2))
            )
        )
        this.target.rightHand = this
    }
}

class PNJ extends GameObject {
    constructor() {
        super()

        Graphics.addMeshComponent(
            this,
            Graphics.getGLTFModel('peasant_girl')!
        )

        vec3.set(this.transform.position, 2, -2, 2)

        quat.rotateY(this.transform.rotation, this.transform.rotation, Math.PI)
    }
}

class BasicScene extends Scene {
    static Managers = [
        Physics3D,
    ];

    async Setup() {
        await Graphics.loadGLTF('model', '/models/stone.gltf')
        await Graphics.loadGLTF('terrain', '/models/terrain.gltf')
        await Graphics.loadGLTF('y_bot', '/models/y_bot.gltf')
        await Graphics.loadGLTF('y_bot', '/models/y_bot_animations.gltf')
        await Graphics.loadGLTF('z_bot', '/models/y_bot.gltf')
        await Graphics.loadGLTF('z_bot', '/models/y_bot_animations.gltf')
        await Graphics.loadGLTF('x_bot', '/models/x_bot.gltf')
        await Graphics.loadGLTF('x_bot_ss', '/models/x_bot_ss.gltf')
        

        await Graphics.loadGLTF('paladin', '/models/paladin.gltf')
        await Graphics.loadGLTF('peasant_girl', '/models/peasant_girl.gltf')
        

        /*{
            const o = new TestGO()
            vec3.set(o.transform.position, 0, 0, -5)
            Instance.Instantiate(o)
        }*/
        /*
        {
            const light = new GameObject()
            Graphics.lightManager.addDirectionalLightComponent(light, {
                color: vec3.fromValues(1,1,1),
                intensity: 1,
                shadowMapsNum: 4,
            })
            const eye = vec3.fromValues(0,0,0)
            const dir = vec3.fromValues(1, -3, 2)
            vec3.add(dir, dir, eye)
            light.transform.lookAt(eye, dir, vec3.fromValues(0, 1, 0))
            Instance.Instantiate(light)
        }
        
        
        /*
        {
            const light = new GameObject(Engine)
            Graphics.lightManager.addSpotLightComponent(light, {
                color: vec3.fromValues(1,1,1),
                intensity: 25,
                radius: 30, 
                halfAngle: Math.PI*0.25,
                shadowMap: true
            })
            vec3.set(light.transform.position, -3, 1, 0.5)
            quat.rotationTo(light.transform.rotation, [0, 0, -1], vec3.normalize(vec3.create(), vec3.fromValues(1, -2, 0)))
            Instance.Instantiate(light)
        }
        */
        /*
        {
            const light = new Light(vec3.fromValues(1,.1,.1))
            vec3.set(light.transform.position, 3, 0, 0)
            Instance.Instantiate(light)
        }
        {
            const light = new Light()
            vec3.set(light.transform.position, -3, 0, 0)
            Instance.Instantiate(light)
        }*/
        //const player = Instance.Instantiate(new Player(Engine))
        const player = Instance.Instantiate(new ZBot())
        Instance.Instantiate(new Floor())
        Instance.Instantiate(new Camera(player.transform))
        /*
        Instance.Instantiate(new RO())
        //Instance.Instantiate(new GeoObject())
        Instance.Instantiate(new PNJ())
        */
        Instance.Instantiate(new Ambient())
        /*
        //const terrain = Instance.Instantiate(new Terrain())
        //terrain.target = player.transform

        {
            const o = new GameObject()
            Graphics.addMeshComponent(
                o, new Mesh(
                    Geometry.cuboid(30, 1, 30),
                    new Material(Graphics.shaders.getSrc('vertexShader'), Graphics.shaders.getSrc('fragmentShader'))
                        .useColor(vec3.fromValues(1, 1, 1))
                        .setMetalness(0)
                        .setRoughness(1)
                )
            )
            o.transform.position[1] = -3.005
            Instance.Instantiate(o)
        }

        */
        Instance.Instantiate(new Box(0, 0, -16))
    }
}

function cylinderGeometry(height: number, radius: number) {
    const geometry = new Geometry()
    const n = 32
    const positions = new Float32Array((n + 1) * 6)
    for (let i = 0; i < n; i++) {
        const index = i * 3
        const angle = i * 2 * Math.PI / n

        positions[index + 0] = Math.cos(angle) * radius
        positions[index + 1] = Math.sin(angle) * radius
        positions[index + 2] = -height / 2

        positions[index + (n + 1) * 3 + 0] = Math.cos(angle) * radius
        positions[index + (n + 1) * 3 + 1] = Math.sin(angle) * radius
        positions[index + (n + 1) * 3 + 2] = height / 2
    }
    positions[n * 3 + 0] = 0
    positions[n * 3 + 1] = 0
    positions[n * 3 + 2] = -height / 2
    positions[(2 * n + 1) * 3 + 0] = 0
    positions[(2 * n + 1) * 3 + 1] = 0
    positions[(2 * n + 1) * 3 + 2] = height / 2

    const indices = new Uint32Array(n * 12)
    for (let i = 0; i < n; i++) {
        indices[i * 3 + 0] = i
        indices[i * 3 + 2] = (i + 1) % n
        indices[i * 3 + 1] = n

        indices[(n + i) * 3 + 0] = n + 1 + i
        indices[(n + i) * 3 + 1] = n + 1 + ((i + 1) % n)
        indices[(n + i) * 3 + 2] = n + 1 + n

        indices[(2*n + i) * 3 + 0] = i
        indices[(2*n + i) * 3 + 1] = (i + 1) % n
        indices[(2*n + i) * 3 + 2] = n + 1 + i

        indices[(3*n + i) * 3 + 1] = n + 1 + i
        indices[(3*n + i) * 3 + 0] = n + 1 + ((i + 1) % n)
        indices[(3*n + i) * 3 + 2] = (i + 1) % n
    }

    const gl = Graphics.context
    geometry.attributes['Position'] = new BufferView(
        Graphics.createBuffer(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW),
        3, gl.FLOAT, 0, 0
    )
    geometry.indices = new BufferView(
        Graphics.createBuffer(gl.ELEMENT_ARRAY_BUFFER, indices, gl.STATIC_DRAW),
        1, gl.UNSIGNED_INT, 0, 0
    )

    geometry.mode = gl.TRIANGLES
    geometry.count = indices.length
    
    return geometry
}

function coneGeometry(height: number, radius: number) {
    const geometry = new Geometry()
    const n = 32
    const positions = new Float32Array(n * 3 + 6)
    for (let i = 0; i < n; i++) {
        const index = i * 3
        const angle = i * 2 * Math.PI / n
        positions[index + 0] = Math.cos(angle) * radius
        positions[index + 1] = Math.sin(angle) * radius
        positions[index + 2] = -height / 2
    }
    positions[(n + 0) * 3 + 0] = 0
    positions[(n + 0) * 3 + 1] = 0
    positions[(n + 0) * 3 + 2] = -height / 2
    positions[(n + 1) * 3 + 0] = 0
    positions[(n + 1) * 3 + 1] = 0
    positions[(n + 1) * 3 + 2] = height / 2

    const base = n
    const tip = n + 1

    const indices = new Uint32Array(n * 6)
    for (let i = 0; i < n; i++) {
        indices[i * 6 + 0] = i
        indices[i * 6 + 1] = (i + 1) % n
        indices[i * 6 + 2] = tip
        indices[i * 6 + 3] = i
        indices[i * 6 + 4] = base
        indices[i * 6 + 5] = (i + 1) % n
    }

    const gl = Graphics.context
    geometry.attributes['Position'] =  new BufferView(
        Graphics.createBuffer(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW),
        3, gl.FLOAT, 0, 0
    )
    geometry.indices = new BufferView(
        Graphics.createBuffer(gl.ELEMENT_ARRAY_BUFFER, indices, gl.STATIC_DRAW),
        1, gl.UNSIGNED_INT, 0, 0
    )

    geometry.mode = gl.TRIANGLES
    geometry.count = n * 6
    
    return geometry
}

class Bullet extends GameObject {
    static geometry?: Geometry
    static material?: Material

    static mesh?: Mesh

    static pool: Mesh[] = []

    age = 0

    direction: vec2

    position: vec2
    prevPosition: vec2

    frame = 0

    constructor(pos: vec3, rot: number) {
        super()

        if (Bullet.pool.length === 0) {
            if (Bullet.material === undefined) {
                Bullet.material = Material.Default()
                .useColor(vec3.fromValues(0, 0, 0))
                .setMetalness(0)
                .setRoughness(1)
                .useEmission(vec3.fromValues(7, 1, 1))
            }
            if (Bullet.geometry === undefined) {
                Bullet.geometry = cylinderGeometry(1, 0.2)
            }
            if (Bullet.mesh === undefined) {
                Bullet.mesh = new Mesh(Bullet.geometry, Bullet.material)
            }
            for (let i = 0; i < 1; i++) {
                Bullet.pool.push(new Mesh(Bullet.geometry, Bullet.material))
            }
        }
        const mesh = Bullet.pool.pop()!

        Graphics.addMeshComponent(this, mesh)

        vec3.copy(this.transform.position, pos)
        vec3.set(this.transform.scale, 1, 1, 1)

        quat.identity(this.transform.rotation)
        quat.rotateZ(this.transform.rotation, this.transform.rotation, rot)
        quat.rotateY(this.transform.rotation, this.transform.rotation, Math.PI / 2)
        this.direction = vec2.fromValues(Math.cos(rot), Math.sin(rot))

        this.position = vec2.fromValues(pos[0], pos[1])
        this.prevPosition = vec2.clone(this.position)
    }

    Init() {
        this.age = 0
    }

    Destroy() {
        Bullet.pool.push(this.getComponent(MeshComponent).mesh as Mesh)
    }

    Update() {
        const newAge = Math.min(2, this.age + Time.deltaTime)
        const deltaTime = Math.min(newAge - this.age, Time.deltaTime)
        this.age = newAge

        const SPEED = 100

        const d = vec2.scale(vec2.create(), this.direction, deltaTime * SPEED)

        const newPos = vec2.add(vec2.create(), this.transform.position as vec2, d)

        const ray = new (Physics2D.RAPIER.Ray)(
            { x: this.transform.position[0], y: this.transform.position[1] },
            { x: this.direction[0], y: this.direction[1] }
        )

        const hit = Physics2D.raycast(
            ray, deltaTime * SPEED, true,
            PHYSICS_GROUPS.OTHER | ((PHYSICS_GROUPS.ENEMY | PHYSICS_GROUPS.STATIC) << 16)
        )
        if (hit !== null) {
            const point = vec2.fromValues(
                this.transform.position[0] + this.direction[0] * hit.toi,
                this.transform.position[1] + this.direction[1] * hit.toi
            )
            Instance.Instantiate(new Explosion(point[0], point[1], 0))

            const collider = Physics2D
                .getRigidBodyFromColliderHandle(hit.collider.handle)?.component.parent
            if (collider) {
                if (collider instanceof Enemy) {
                    collider.takeDamage()
                }
                else if (collider instanceof End) {
                    //
                }
                else {
                    const mesh = collider.getComponent(MeshComponent).mesh as Mesh
                    mesh.ro.useProgram()
                    mesh.ro.setUniform("uColor", new Float32Array([
                        Math.random(), Math.random(), Math.random()
                    ]))
                }
            }

            Instance.Destroy(this)
            return
        }

        if (this.age >= 2) {
            Instance.Destroy(this)
            return
        }

        vec2.copy(this.position, newPos)

        vec2.add(this.transform.position as vec2, this.position, this.prevPosition)
        vec2.scale(this.transform.position as vec2, this.transform.position as vec2, 0.5)

        this.transform.scale[2] = vec2.distance(this.position, this.prevPosition)

        vec2.copy(this.prevPosition, this.position)
    }
}

class Explosion extends GameObject {
    static geometry?: Geometry
    static material?: Material

    static pool: Mesh[] = []

    private age = 0

    constructor(x: number, y: number, z: number, ) {
        super()

        const vertexShaderSrc = Graphics.shaders.getSrc('vertexShader')
        const fragmentShaderSrc = Graphics.shaders.getSrc('fragmentShader')
        if (Explosion.pool.length === 0) {
            if (Explosion.material === undefined) {
                Explosion.material = Material.Default()
                .useColor(vec3.fromValues(0, 0, 0))
                .setMetalness(0)
                .setRoughness(1)
                .useEmission(vec3.fromValues(9, 5, 2))
            }
            if (Explosion.geometry === undefined) {
                Explosion.geometry = Geometry.sphere(1, 3)
            }
            for (let i = 0; i < 64; i++) {
                Explosion.pool.push(new Mesh(Explosion.geometry, Explosion.material))
            }
        }
        Graphics.addMeshComponent(this, Explosion.pool.pop()!)

        vec3.set(this.transform.position, x, y, z)
    }

    Destroy() {
        Explosion.pool.push(this.getComponent(MeshComponent).mesh as Mesh)
    }

    Update() {
        this.age += Time.deltaTime * 5.
        if (this.age > 1) {
            Instance.Destroy(this)
            return
        }

        const s = (this.age < 0.1 ? this.age / 0.1 : (this.age > 0.9 ? (0.1 - this.age + 0.9) / 0.1 : 1)) * 1
        vec3.set(this.transform.scale, s, s, s)
    }
}

class Cone extends GameObject {
    rot = 0

    cooldown = 0

    gunState = 0

    constructor(x: number, y: number, z: number) {
        super()

        const material = Material.Default()
            .useColor(vec3.fromValues(1,1,1))
            .setMetalness(0)
            .setRoughness(1)
            //.useEmission(vec3.fromValues(0, 0, 0))
        //Graphics.addMeshComponent(this, new Mesh(coneGeometry(2, 1), material))
        Graphics.addMeshComponent(this, new Mesh(coneGeometry(2, 1), material))

        const rigidBody = Physics2D.RAPIER.RigidBodyDesc.dynamic()
            .lockRotations()
        rigidBody.mass = 1
        const collider = Physics2D.RAPIER.ColliderDesc.ball(1)
            .setCollisionGroups(PHYSICS_GROUPS.PLAYER | ((PHYSICS_GROUPS.STATIC | PHYSICS_GROUPS.SENSOR) << 16))
        this.addComponent(new RigidBody2DComponent(this, collider, rigidBody))

        vec3.set(this.transform.position, x, y, z)
    }

    Update() {
        if (Inputs.get(' ') && Time.time > this.cooldown) {
            const angle = Math.PI / 256
            const scale = (this.gunState - 0.5) * 1
            const p = vec3.fromValues(-Math.sin(this.rot) * scale, Math.cos(this.rot) * scale, 0)
            const bullet = new Bullet(vec3.add(p, this.transform.position, p), this.rot + Math.random() * angle - angle * 0.5)
            Instance.Instantiate(bullet)
            this.cooldown = Time.time + 0.025
            this.gunState ^= 1
        }

        const ROT_SPEED = Math.PI * 1
        const SPEED = 1
        let rot = 0

        if (Inputs.get('q')) {
            rot += ROT_SPEED
        }
        if (Inputs.get('d')) {
            rot -= ROT_SPEED
        }

        const rigidBody = this.getComponent(RigidBody2DComponent)
        const deltaTime = Time.deltaTime
        const accelerating = Inputs.get('z')
        const acceleration = vec2.create()
        if (accelerating) {
            vec2.add(acceleration, acceleration, vec2.fromValues(Math.cos(this.rot) * SPEED, Math.sin(this.rot) * SPEED))
            rigidBody.rigidBody.setLinearDamping(0.5)
        }
        else {
            rigidBody.rigidBody.setLinearDamping(1)
        }
        /*
        const velocity = rigidBody.rigidBody.linvel()
        const scale = acceleration ? 0.05 : 0.1
        acceleration[0] -= velocity.x * scale
        acceleration[1] -= velocity.y * scale
        */

        this.getComponent(RigidBody2DComponent).rigidBody.applyImpulse(
            new (Physics2D.RAPIER.Vector2)(acceleration[0], acceleration[1]),
            true
        )

        this.rot += rot * deltaTime

        quat.setAxisAngle(this.transform.rotation, [0, 1, 0], Math.PI / 2)
        const q = quat.setAxisAngle(quat.create(), [0, 0, 1], this.rot)

        quat.mul(this.transform.rotation, q, this.transform.rotation)
    }
}

class End extends GameObject {
    constructor(x: number, y: number) {
        super()

        {
            const child = new GameObject()

            const material = Material.Default()
            .useColor(vec3.fromValues(1,1,1))
            .useNormals()
            .setMetalness(0)
            .setRoughness(1)
            .useEmission(vec3.fromValues(0, 0, 0))
            Graphics.addMeshComponent(child, new Mesh(Geometry.cuboid(3,3,1), material))

            child.transform.position[2] = -2
            this.addChild(child)
        }

        vec3.set(this.transform.position, x, y, 0)
        const rb = Physics2D.RAPIER.RigidBodyDesc.fixed()
        const collider = Physics2D.RAPIER.ColliderDesc.cuboid(3, 3)
            .setCollisionGroups(PHYSICS_GROUPS.SENSOR | (PHYSICS_GROUPS.PLAYER << 16))
        this.addComponent(new Sensor2DComponent(this,
            collider,
            rb,
            (() => {
                //Events.triggerEvent('OnEndPlatform')
            }).bind(this)
        ))
    }
}

class ShooterCamera extends GameObject {
    target?: GameObject

    constructor() {
        super()

        const aspect = Graphics.canvasWidth / Graphics.canvasHeight
        this.addComponent(new CameraComponent(this, {
            type: 'perspective',
            fovy: 60 / 180 * Math.PI,
            aspect,
            near: 0.01,
            far: 100
        }))
    }
    Init() {
        vec3.set(this.transform.position, 0, 0, 70)
        Graphics.camera = this.getComponent(CameraComponent)
    }
    Update() {
        if (this.target) {
            vec2.copy(this.transform.position as vec2, this.target.transform.position as vec2)
        }
    }
    Destoy() {
        Graphics.camera = null
    }
}

class ShooterScene extends Scene {
    static Managers = [
        Physics2D,
    ]

    async Setup() {
        await Graphics.loadGLTF('model', '/models/stone.gltf')

        const HUDContainer = HUD.container

        const endButton = document.createElement('div')
        endButton.classList.add('end-button')

        const endButtonContent = document.createElement('div')
        endButtonContent.classList.add('content')
        endButtonContent.innerText = 'E'
        endButton.appendChild(endButtonContent)

        const endButtonText = document.createElement('div')
        endButtonText.classList.add('text')
        endButtonText.innerText = 'to land'
        endButton.appendChild(endButtonText)

        HUDContainer.appendChild(endButton)

        const camera = new ShooterCamera() 

        Instance.Instantiate(camera)

        Instance.Instantiate(new Ambient())

        const light = new GameObject()
        Graphics.lightManager.addDirectionalLightComponent(light, {
            color: vec3.fromValues(1,1,1),
            intensity: 1,
            shadowMapsNum: 1,
        })
        quat.rotateY(light.transform.rotation, light.transform.rotation, Math.PI * -0.1)
        quat.rotateX(light.transform.rotation, light.transform.rotation, Math.PI * -0.25)
        Instance.Instantiate(light)

        const chunkSize = 64
        const player = Instance.Instantiate(new Cone(chunkSize / 2, chunkSize / 2, 0))
        camera.target = player

        for (let x = 0; x < 3; x++) {
            for (let y = 0; y < 3; y++) {
                const cx = x * chunkSize
                const cy = y * chunkSize
                Instance.Instantiate(new Obstacle(cx + chunkSize - 4, cy + chunkSize - 4, 0))
                Instance.Instantiate(new Obstacle(cx + chunkSize - 4, cy + 4, 0))
                Instance.Instantiate(new Obstacle(cx + 4, cy + chunkSize - 4, 0))
                Instance.Instantiate(new Obstacle(cx + 4, cy + 4, 0))

                if ((x !== 0 || y !== 0) && (x !== 1 || y !== 0)) {
                    Instance.Instantiate(new Enemy(cx + chunkSize / 2, cy + chunkSize / 2))
                }
                if (x === 1 && y === 0) {
                    Instance.Instantiate(new End(cx + chunkSize / 2, cy + chunkSize / 2))
                }
            }
        } 
    }
}

export function main() {
    Engine.start({
        basic: BasicScene,
        shooter: ShooterScene,
        snake: SnakeScene,
        snakeMenu: SnakeMenuScene,
    }, { sceneName: "snake", data: null })
    return Engine
}


