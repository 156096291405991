import { GameObject } from "./GameObject"
import { Graphics } from "./Graphics"
import { HUD } from "./HUD"
import { Inputs } from "./Inputs"
import { Instance } from "./Instance"
import { Manager } from "./Manager"
import { Scene } from "./Scene"
import { Time } from "./Time"
import { TransformManager } from "./Transform"
import { Events } from "./Events"
import { AudioSystem } from "./graphics/Audio"

const DEFAULT_MANAGERS: Manager[] = [
    TransformManager,
    Events,
    Instance,
    Graphics,
    HUD,
    AudioSystem,
    Inputs,
    Time,
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SceneConstructor = new (...args: any[]) => Scene

//type WasmImport = typeof import('../../wasm')

export class EngineClass {
    window: HTMLDivElement

    private managers: Array<Manager> = []
    private scenes: Record<string, SceneConstructor> = {}

    private currentScene: Scene | null = null
    private sceneChanged = false
    private setupData: {sceneName: string, data: unknown} = {sceneName: '', data: null}

    stopped = false

//    wasm: WasmImport
 
    async start(scenes: Record<string, SceneConstructor>, initialScene: { sceneName: string, data: unknown }) {
//        this.wasm = await import('../../wasm')
        this.stopped = false

        this.window = document.querySelector('.game-window')!

        const managerCtorSet = new Set<Manager>(DEFAULT_MANAGERS)
        this.scenes = scenes
        for (const k in this.scenes) {
            for (const manager of (<typeof Scene><unknown>this.scenes[k]).Managers) {
                managerCtorSet.add(manager)
            }
        }
        for (const manager of managerCtorSet) {
            this.managers.push(manager)
        }

        await Promise.all(this.managers.map(manager => manager.Init?.()))

        this.enterScene(initialScene).then(() => {
            requestAnimationFrame(this.frame.bind(this));
        })
    }

    async enterScene({sceneName, data}: {sceneName: string, data: unknown}): Promise<void> {
        const sceneCtor = this.scenes[sceneName]
        const scene = new sceneCtor(this)

        const managerList = [ ...DEFAULT_MANAGERS, ...(<typeof Scene><unknown>sceneCtor).Managers ]

        for (const manager of managerList) {
            Events.bindEvents(manager, manager);
        }
        for (const manager of managerList) {
            await manager.Setup?.();
        }

        Instance.enterScene()
        Time.enterScene()

        await scene.Setup?.(data)
        this.currentScene = scene
    }

    exitScene(): void {
        Instance.exitScene()

        Events.triggerEvent('Exit')

        Events.resetEvents()

        if (this.currentScene) {
            this.currentScene.Exit?.()
        }
    }

    changeScene(sceneName: string, data?: unknown): void {
        this.setupData = {sceneName, data}
        this.sceneChanged = true
    }

    frame(now: number): void {
        now = now / 1000;

        Time.update(now)
        Instance.update()
        Events.triggerEvent('Update')
        Inputs.update()
        Events.triggerEvent('PhysicsUpdate')
        TransformManager.updateMatrices()
        Events.triggerEvent('Render')

        if (this.sceneChanged) {
            this.exitScene()
            this.enterScene(this.setupData)
            this.setupData = {sceneName: '', data: {}}
            this.sceneChanged = false
        }

        if (this.stopped === false) {
            requestAnimationFrame(this.frame.bind(this));
        }
        else {
            this.clean()
        }
    }

    /*
    getManager<T extends Manager>(c: new (engine: EngineClass) => T): T {
        return this.managers.find(m => m instanceof c) as T
    }
    */
  
    destroy(): void {
        this.stopped = true
    }
    private clean() {
        console.log('ENGINE STOPPED')
        this.exitScene()
        for (const manager of this.managers) {
            manager.Destructor?.()
        }
        this.managers = []

        Events.deleteEvents()
    }
}
  
