import { vec3 } from "gl-matrix"
import { GameObject } from "./engine/GameObject"
import { Graphics } from "./engine/Graphics"
import { Geometry } from "./engine/graphics/Geometry"
import { Material } from "./engine/graphics/Material"
import { Mesh } from "./engine/graphics/Mesh"
import { Physics2D, Physics2DManager, RigidBody2DComponent } from "./engine/Physics"
import { PHYSICS_GROUPS } from "./game/Constants"

export class Obstacle extends GameObject {
    constructor(x: number, y: number, z: number) {
        super()

        const material = Material.Default()
            .useColor(vec3.fromValues(1,1,1))
            .useNormals()
            .useEmission(vec3.fromValues(0, 0, 0))
        Graphics.addMeshComponent(this, new Mesh(Geometry.cuboid(3,3,3), material))

        vec3.set(this.transform.position, x, y, z)
        const rb = Physics2D.RAPIER.RigidBodyDesc.fixed()
        const collider = Physics2D.RAPIER.ColliderDesc.cuboid(3, 3)
            .setCollisionGroups(PHYSICS_GROUPS.STATIC | ((PHYSICS_GROUPS.ENEMY | PHYSICS_GROUPS.PLAYER | PHYSICS_GROUPS.OTHER) << 16))
            .setRestitution(0.5)
        this.addComponent(new RigidBody2DComponent(this, collider, rb))
    }
}
