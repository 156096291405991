import { Engine } from '../Engine'
import { Manager } from './Manager'

export class HUDElement {
    element: HTMLElement

    constructor(element: HTMLElement) {
        this.element = element
    }
}

export class HUDManager extends Manager {
    container = <HTMLDivElement><unknown>null

    elements: HUDElement[] = []

    async Init() {
        this.container = Engine.window.querySelector('.game-hud')!
    }

    async Setup() {
        this.elements = []
    }
    
    add(element: HUDElement) {
        this.container.appendChild(element.element)
        this.elements.push(element)
    }

    Exit() {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild)
        }
        this.elements = []
    }
}

export const HUD = new HUDManager()
