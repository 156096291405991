import { mat4, vec3 } from "gl-matrix"
import { Graphics } from "../Graphics"

type MaterialUniform = { type: 'float', value: Float32Array } |
    { type: 'vec3', value: Float32Array } |
    { type: 'mat4', value: Float32Array } |
    { type: 'int', value: Int32Array }

type MaterialOptions = {
    vertexShader: string
    fragmentShader: string
    defines: Record<string, string>
    uniforms: Record<string, MaterialUniform>
    textures: Record<string, { index: number, texture: WebGLTexture }>
    attributes: Record<string, string>
}

export class Material {
    private vertexShaderSrc: string
    private fragmentShaderSrc: string

    defines: Record<string, string>
    uniforms: Record<string, MaterialUniform>
    textures: Record<string, { index: number, texture: WebGLTexture }>
    attributes: Record<string, string>

    constructor(options: MaterialOptions) {
        this.vertexShaderSrc = options.vertexShader
        this.fragmentShaderSrc = options.fragmentShader

        this.defines = options.defines
        this.uniforms = options.uniforms
        this.attributes = options.attributes
        this.textures = options.textures
    }

    static Default(options?: Partial<MaterialOptions>) {
        options ??= {}
        return new Material({
            vertexShader: options.vertexShader ?? Graphics.shaders.getSrc('vertexShader'),
            fragmentShader: options.fragmentShader ?? Graphics.shaders.getSrc('fragmentShader'),
            defines: { ...options.defines },
            uniforms: {
                uTransform: { type: 'mat4', value: new Float32Array(16) },
                uView: { type: 'mat4', value: new Float32Array(16) },
                uProjection: { type: 'mat4', value: new Float32Array(16) },
                uMetalness: { type: 'float', value: new Float32Array([0]) },
                uRoughness: { type: 'float', value: new Float32Array([1]) },
                ...options.uniforms,
            },
            textures: { ...options.textures },
            attributes: { aPosition: 'Position', ...options.attributes },
        })
    }

    useColorAttribute() {
        this.defines['USE_COLOR_ATTRIBUTE'] = ''
        this.attributes['aColor'] = 'Color'
        return this
    }
    useColor(color: Float32Array | vec3) {
        this.uniforms['uColor'] = { type: 'vec3', value: color as Float32Array }
        this.defines['USE_COLOR'] = ''
        return this
    }
    useNormals() {
        this.defines['NORMAL'] = ''
        this.attributes['aNormal'] = 'Normal'
        return this
    }
    useEmission(color: Float32Array | vec3) {
        this.uniforms['uEmission'] = { type: 'vec3', value: color as Float32Array }
        this.defines['USE_EMISSION'] = ''
        return this
    }

    useColorMap(texture: WebGLTexture) {
        this.defines['COLOR_MAP'] = ''
        this.uniforms['uColorMap'] = { type: 'int', value: new Int32Array(1) }
        this.defines['UV'] = ''
        this.attributes['aUV'] = 'UV0'
        this.textures['uColorMap'] = { index: -1, texture }
        return this
    }

    setMetalness(v: number) {
        this.uniforms.uMetalness.value[0] = v
        return this
    }

    setRoughness(v: number) {
        this.uniforms.uRoughness.value[0] = v
        return this
    }

    useSkin(jointCount: number) {
        this.attributes['aJoints'] = 'Joints0'
        this.attributes['aWeights'] = 'Weights0'
        this.defines['SKINNED'] = ''
        this.defines['SKIN_MATRICES_NUM'] = jointCount.toString()
        return this
    }

    useLocalTransform(matrix: mat4) {
        this.defines['LOCAL_TRANSFORM'] = ''
        this.uniforms['uLocalTransform'] = {
            type: 'mat4',
            value: matrix as Float32Array,
        }
        return this
    }

    build(): { vertex: WebGLShader, fragment: WebGLShader } {
        const defines = { ...this.defines }

        Object.keys(this.textures).forEach((k, i) => {
            this.uniforms[k].value[0] = i
            this.textures[k].index = i
        })

        return {
            vertex: Graphics.shaders.compileShaderFromStr(this.vertexShaderSrc, {type: 'vertex', defines}),
            fragment: Graphics.shaders.compileShaderFromStr(this.fragmentShaderSrc, {type: 'fragment', defines}),
        }
    }

    buildInstanced(): { vertex: WebGLShader, fragment: WebGLShader } {
        const defines = {
            ...this.defines,
            INSTANCED: '',
        }

        Object.keys(this.textures).forEach((k, i) => {
            this.uniforms[k].value[0] = i
            this.textures[k].index = i
        })

        return {
            vertex: Graphics.shaders.compileShaderFromStr(this.vertexShaderSrc, {type: 'vertex', defines}),
            fragment: Graphics.shaders.compileShaderFromStr(this.fragmentShaderSrc, {type: 'fragment', defines}),
        }
    }
}
