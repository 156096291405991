import { vec3 } from "gl-matrix"
import { Component } from "../../Component"
import { GameObject } from "../../GameObject"
import { AO_INDEX, Graphics } from "../../Graphics"
import { RenderableObject } from "../RenderableObject"
import { addSetUniform, setGbuffer } from "../utils"

export class AmbientLightComponent extends Component {
    private ro: RenderableObject

    constructor(parent: GameObject, color: vec3, intensity: number) {
        super(parent)

        const vShader = Graphics.shaders.getShader('basicVS')
        const fShader = Graphics.shaders.getShader('ambientFS')
        const gl = Graphics.context

        console.log(color, intensity)
        this.ro = new RenderableObject(gl, vShader, fShader, gl.TRIANGLE_STRIP, 4)
        this.ro.useProgram()
        addSetUniform(this.ro, 'uAO', 'int', new Int32Array([AO_INDEX]))
        addSetUniform(this.ro, 'uColor', 'vec3', color)
        addSetUniform(this.ro, 'uIntensity', 'float', new Float32Array([intensity]))

        setGbuffer(this.ro)
    }
    render() {
        this.ro.render()
    }
}
