import { vec3 } from "gl-matrix"
import { GameObject } from "../engine/GameObject"
import { Geometry } from "../engine/graphics/Geometry"
import { Material } from "../engine/graphics/Material"
import { Physics2D, RigidBody2DComponent } from "../engine/Physics"
import { Graphics } from "../engine/Graphics"
import { Instance } from "../engine/Instance"
import { Mesh } from "../engine/graphics/Mesh"

export class Enemy extends GameObject {
    static geometry: Geometry

    health: number

    constructor(x: number, y: number) {
        super()

        const radius = 2

        const material = Material.Default()
            .useColor(vec3.fromValues(1,1,1))
            .useNormals()
            .useEmission(vec3.fromValues(0, 0, 0))
        Graphics.addMeshComponent(this, new Mesh(
            Geometry.sphere(radius, 2),
            material
        ))

        {
            const child = new GameObject()
            Graphics.addMeshComponent(child, new Mesh(
                Geometry.cuboid(
                    3, 3, 1
                ), material
            ))

            vec3.set(child.transform.position, 0, 0, -2.5)

            this.addChild(child)
        }

        const rb = Physics2D.RAPIER.RigidBodyDesc.dynamic()
        this.addComponent(new RigidBody2DComponent(this,
            Physics2D.RAPIER.ColliderDesc.ball(radius),
            rb
        ))

        this.health = 16

        vec3.set(this.transform.position, x, y, 0)
    }

    takeDamage() {
        --this.health
        if (this.health <= 0) {
            Instance.Destroy(this)
        }
    }
}
