import { Component } from "../Component";
import { GameObject } from "../GameObject";
import { Manager } from "../Manager";

export class AudioSourceComponent extends Component {
    constructor(parent: GameObject) {
        super(parent)
    }

    async play(name: string) {
        const source = AudioSystem.context.createBufferSource()
        source.buffer = AudioSystem.buffers[name]
        source.connect(AudioSystem.context.destination)

        source.start()
    }
}

export class AudioManager extends Manager {
    context: AudioContext
    buffers: Record<string, AudioBuffer>

    async Init() {
        this.context = new AudioContext()
        this.buffers = {}
    }

    Destructor() {
        console.warn('Should free sounds')
        this.buffers = {}
    }

    async loadSound(name: string, url: string) {
        const res = await fetch(url)
        const arrayBuffer = await res.arrayBuffer()
        this.buffers[name] = await this.context.decodeAudioData(arrayBuffer)
    }
}

export const AudioSystem = new AudioManager()
