import { mat4, quat, vec3 } from 'gl-matrix';
import * as GLTF from './GLTFLoaderTypes'
import { Geometry } from './Geometry';
import { Material } from './Material';
import { InstancedMesh, Mesh } from './Mesh';
import { assert } from './utils';
import { GLTFSkin, GLTFSkins, JointNode, SkinsInfo } from './GLTF/Skins';
import { Graphics } from '../Graphics';
import { Engine } from '@/js/Engine';
import { BufferView } from './BufferView';

const ACCESSOR_SIZE = {
    SCALAR: 1,
    VEC2: 2,
    VEC3: 3,
    VEC4: 4,
    MAT2: 4,
    MAT3: 9,
    MAT4: 16,
}

type ParsedBufferView = {
    buffer: WebGLBuffer,
    target: number,
    stride: number,
}

type Primitive = {
    geometry: Geometry
    material: Material
    mesh?: Mesh | InstancedMesh
    skinUBOIndex?: number
}

type RawMesh = {
    primitives: Primitive[],
    skin?: GLTFSkin,
}

type MeshInstancePrimitive = {
    geometry: Geometry
    material: Material
    mesh: Mesh | InstancedMesh
    skinUBOIndex?: number
}

type MeshInstance = {
    primitives: MeshInstancePrimitive[],
    skin?: GLTFSkin,
    localTransform: mat4,
}

export type GLTFRawAnimation = {
    name?: string
    inputs: Float32Array,
    channels: { target: { name: string, path: "rotation" | "translation" | "scale" }, output: Float32Array }[],
    duration: number,
}

/*
export class IK extends Component {
    private gltfMesh: GLTFMesh
    private skins: SkinsInfo

    meshTransformMat: mat4

    feetTargets = [ vec3.create(), vec3.create() ]
    feetPositions = [ vec3.create(), vec3.create() ]
    feet = [ vec3.create(), vec3.create() ]
    legsNodes: JointNode[][] = []
    feetAnchored = [ true, true ]
    feetPreviousPosition = [ vec3.create(), vec3.create() ]

    transform: Transform

    constructor(parent: GameObject, mesh: GLTFMesh, meshTransformMat: mat4) {
        super(parent)

        this.gltfMesh = mesh
        this.skins = mesh.skins
        this.meshTransformMat = meshTransformMat

        this.transform = this.parent.transform
    }

    Init() {
        const leftFootJoint = this.skins.jointNodes.find((n) => n.name === 'mixamorig:LeftFoot') as JointNode
        vec3.transformMat4(this.feet[0], this.feet[0], leftFootJoint.globalForwardMatrix)
        vec3.transformMat4(this.feet[0], this.feet[0], this.meshTransformMat)
        
        const rightFootJoint = this.skins.jointNodes.find((n) => n.name === 'mixamorig:RightFoot') as JointNode
        vec3.transformMat4(this.feet[1], this.feet[1], rightFootJoint.globalForwardMatrix)
        vec3.transformMat4(this.feet[1], this.feet[1], this.meshTransformMat)

        const transformMat = this.transform.getMatrix()

        vec3.transformMat4(this.feetTargets[0], this.feet[0], transformMat)
        vec3.copy(this.feetPositions[0], this.feetTargets[0])

        vec3.transformMat4(this.feetTargets[1], this.feet[1], transformMat)
        vec3.copy(this.feetPositions[1], this.feetTargets[1])

        this.legsNodes[0] = [
            this.skins.jointNodes.find((n) => n.name === 'mixamorig:LeftUpLeg') as JointNode,
            this.skins.jointNodes.find((n) => n.name === 'mixamorig:LeftLeg') as JointNode,
            this.skins.jointNodes.find((n) => n.name === 'mixamorig:LeftFoot') as JointNode,
        ]

        this.legsNodes[1] = [
            this.skins.jointNodes.find((n) => n.name === 'mixamorig:RightUpLeg') as JointNode,
            this.skins.jointNodes.find((n) => n.name === 'mixamorig:RightLeg') as JointNode,
            this.skins.jointNodes.find((n) => n.name === 'mixamorig:RightFoot') as JointNode,
        ]

        const hips = this.skins.jointNodes.find((n) => n.name === 'mixamorig:Hips') as JointNode
        hips.translation[2] += 10

        ///////////////////////// DEPRECATED
        this.gltfMesh.updateJoints()
        /////////////////////////
    }

    update(objectTransformMat: mat4, velocity: vec3, helpersPositions: [vec3, vec3]) {
        if (this.feetAnchored[0] === false) {
            this.feetTargets[0][2] = this.feetPreviousPosition[0][2] + velocity[2] / 2
            if (this.feetTargets[0][2] <= this.transform.position[2]) {
                this.feetAnchored[0] = true
                console.log('anchored')
            }
        }
        if (this.feetAnchored[1] === false) {
            this.feetTargets[1][2] = this.feetPreviousPosition[1][2] + velocity[2] / 2
            if (this.feetTargets[1][2] <= this.transform.position[2]) {
                this.feetAnchored[1] = true
                console.log('anchored')
            }
        }
        if (this.feetAnchored[0] === true && this.feetAnchored[1] === true) {
            if (velocity[2] > 0.0001) {
                const closestIndex = this.feetTargets[0][2] > this.feetTargets[1][2] ? 1 : 0
                console.log(closestIndex)
                vec3.copy(this.feetPreviousPosition[closestIndex], this.feetTargets[closestIndex])
                this.feetTargets[closestIndex][2] = this.feetPreviousPosition[closestIndex][2] + velocity[2] / 2
                this.feetAnchored[closestIndex] = false
            }
        }
        vec3.copy(helpersPositions[0], this.feetTargets[0])
        vec3.copy(helpersPositions[1], this.feetTargets[1])

        const transformMat = mat4.mul(mat4.create(), objectTransformMat, this.meshTransformMat)

        this.ccd(transformMat, this.legsNodes[0], this.feetTargets[0])
        this.ccd(transformMat, this.legsNodes[1], this.feetTargets[1])
    }

    ccd(transformMat: mat4, nodes: JointNode[], target: vec3) {
        const numIter = 3
        for (let iter = 0; iter < numIter; iter++) {
            for (let i = 0; i < 3; i++) {
                const nMat = mat4.mul(mat4.create(), transformMat, nodes[i].globalForwardMatrix)
                const inv = mat4.invert(mat4.create(), nMat)
                const targetLocal = vec3.transformMat4(vec3.create(), target, inv)
                const eff = vec3.fromValues(0, 0, 0)

                for (let j = nodes.length - 1; j > i; j--) {
                    vec3.transformMat4(eff, eff, nodes[j].localForwardMatrix)
                }

                vec3.normalize(eff, eff)
                vec3.normalize(targetLocal, targetLocal)
                const rot = quat.rotationTo(quat.create(), eff, targetLocal)
                quat.mul(nodes[i].rotation, nodes[i].rotation, rot)

                mat4.fromRotationTranslationScale(nodes[i].localForwardMatrix, nodes[i].rotation, nodes[i].translation, nodes[i].scale)
                for (let j = i; j < nodes.length; j++) {
                    mat4.mul(nodes[j].globalForwardMatrix, nodes[j].parent!.globalForwardMatrix, nodes[j].localForwardMatrix)
                }
            }
        }
        ///////////////////////// DEPRECATED
        this.gltfMesh.updateJoints()
        /////////////////////////
    }
}
*/

export class GLTFMesh {
    private gl: WebGL2RenderingContext
    private drawFuncs: ((transform: mat4, viewMatrix: mat4, projectionMatrix: mat4) => void)[]
    meshes: RawMesh[]
    meshInstances: MeshInstance[]
    skins: GLTFSkins

    constructor(gl: WebGL2RenderingContext, meshes: RawMesh[], skins: SkinsInfo, meshInstances: MeshInstance[]) {
        this.gl = gl
        this.meshes = meshes
        this.skins = new GLTFSkins(this.gl, skins)
        this.meshInstances = meshInstances
        this.drawFuncs = []

        this.skins.resetJointForwardMatrices()
        this.skins.updateJoints()

        for (const mesh of this.meshInstances) {
            for (const primitive of mesh.primitives) {
                this.drawFuncs.push((transform: mat4, viewMatrix: mat4, projectionMatrix: mat4) => {
                    if (mesh.skin !== undefined) {
                        gl.bindBufferBase(gl.UNIFORM_BUFFER, primitive.skinUBOIndex!, mesh.skin.uboBuffer)
                    }

                    primitive.mesh.render(transform, viewMatrix, projectionMatrix)
                })
            }
        }
    }

    render(transform: mat4, viewMatrix: mat4, projectionMatrix: mat4) {
        for (const drawFunc of this.drawFuncs) {
            drawFunc(transform, viewMatrix, projectionMatrix)
        }
    }
}

export type InstancedOptions = {
    buffer: WebGLBuffer,
    count: number,
}

export class GLTFLoader {
    private gl: WebGL2RenderingContext = <WebGL2RenderingContext><unknown>null
    private gltf: GLTF.GLTF = { asset: { version: '' } }
    private buffers: Uint8Array[] = []
    private parsedBufferViews: ParsedBufferView[] = []
    private meshes: RawMesh[] = []
    private meshInstances: MeshInstance[] = []
    private skins: SkinsInfo = {
        skins: [],
        jointNodes: [],
        inverseBindMatrices: [],
        localForwardMatrices: [],
        globalForwardMatrices: [],
        jointParents: [],
        jointMatrices: [],
    }
    skinNodes: GLTF.GLTFNode[] = []

    instances: {matrix: mat4, node: GLTF.GLTFNode}[][] = []

    instanced?: InstancedOptions

    async load(gl: WebGL2RenderingContext, filename: string, instanced?: InstancedOptions) {
        this.gl = gl
        this.gltf = await fetch(filename).then(f => f.json())

        if (instanced !== undefined) {
            this.instanced = instanced
        }

        this.buffers = this.gltf.buffers?.map(buffer => this.parseBuffer(buffer)) ?? []
        this.parsedBufferViews = Array(this.gltf.bufferViews?.length ?? 0)

        let mesh: GLTFMesh | undefined = undefined
        if (this.gltf.meshes !== undefined) {
            this.instances = this.gltf.meshes?.map(() => []) ?? []

            assert(this.gltf.scene !== undefined)
            assert(this.gltf.scenes !== undefined)
            assert(this.gltf.scenes.length !== undefined)

            const scene = this.gltf.scenes[this.gltf.scene]

            assert(scene.nodes !== undefined)
            assert(this.gltf.nodes !== undefined)

            for (const nodeIdx of scene.nodes) {
                const node = this.gltf.nodes[nodeIdx]
                this.parseNode(node, null)
            }

            this.skins = this.parseSkins(this.gltf.skins ?? [])
            assert(this.skins.skins.length < 2, 'more than 1 skin: not tested')
            this.createInstances()
            mesh = new GLTFMesh(gl, this.meshes, this.skins, this.meshInstances)
            console.log(this.meshInstances)
        }

        const rawAnimations: GLTFRawAnimation[] | undefined = this.gltf.animations?.map(animation => this.parseAnimation(animation))

        return {
            mesh,
            rawAnimations,
        }
    }

    createInstances() {
        const gl = this.gl
        for (let i = 0; i < this.instances.length; i++) {
            const instance = this.instances[i]
            assert(instance.length !== 0)

            for (const subInstance of instance) {
                const skin = subInstance.node.skin

                this.meshes[i] = this.parseMesh(this.gltf.meshes![i], skin)

                const meshInstance: MeshInstance = {
                    primitives: [],
                    localTransform: subInstance.matrix,
                }
                if (subInstance.node.skin !== undefined) {
                    meshInstance.skin = this.skins.skins[subInstance.node.skin]
                }
                for (const primitive of this.meshes[i].primitives) {
                    primitive.material.useLocalTransform(subInstance.matrix)
                    if (this.instanced !== undefined) {
                        primitive.mesh = new InstancedMesh(primitive.geometry, primitive.material, this.instanced.buffer, this.instanced.count)
                    }
                    else {
                        primitive.mesh = new Mesh(primitive.geometry, primitive.material)
                    }
                    if (skin !== undefined) {
                        const program = primitive.mesh.ro.getProgram()
                        const skinUBOIndex = gl.getUniformBlockIndex(program, 'uJoints')
                        const blockSize = gl.getActiveUniformBlockParameter(program, skinUBOIndex, gl.UNIFORM_BLOCK_DATA_SIZE)
                        primitive.skinUBOIndex = skinUBOIndex
                        assert(blockSize === this.skins.skins[skin]?.buffer.byteLength, 'ubo size does not match buffer size')
                    }

                    meshInstance.primitives.push({
                        geometry: primitive.geometry,
                        material: primitive.material,
                        mesh: primitive.mesh,
                        skinUBOIndex: primitive.skinUBOIndex,
                    })
                }
                this.meshInstances.push(meshInstance)
            }
        }
    }

    parseNode(node: GLTF.GLTFNode, parentMatrix: mat4 | null) {
        assert(node.camera === undefined)
        assert(node.weights === undefined)

        const matrix = parentMatrix ? mat4.clone(parentMatrix) : mat4.create()

        if (node.matrix) {
            const localMat = mat4.fromValues(...node.matrix)
            mat4.mul(matrix, matrix, localMat)
        }
        else {
            const localMat = mat4.create()
            mat4.fromRotationTranslationScale(
                localMat,
                node.rotation ? quat.fromValues(...node.rotation) : quat.create(),
                node.translation ? vec3.fromValues(...node.translation) : vec3.create(),
                node.scale ? vec3.fromValues(...node.scale) : vec3.fromValues(1,1,1)
            )
            mat4.mul(matrix, matrix, localMat)
        }
        if (node.mesh !== undefined) {
            this.instances[node.mesh].push({ node: node, matrix })
            if (node.skin !== undefined) {
                this.skinNodes.push(node)
            }
        }
        else {
            assert(node.skin === undefined, 'a node should not have a skin with no mesh')
        }

        if (node.children) {
            for (const child of node.children) {
                this.parseNode(this.gltf.nodes![child], matrix)
            }
        }
    }

    parseBuffer(buffer: GLTF.Buffer) {
        assert(buffer.uri !== undefined)

        const match = buffer.uri.match(/^data:application\/octet-stream;base64,(.*)$/)
        assert(match !== null)

        const dataStr = atob(match[1])
        const data = new Uint8Array(dataStr.length).map((_, i) => dataStr.charCodeAt(i))
        return data
    }

    parseBufferView(bufferView: GLTF.BufferView, target: number): ParsedBufferView {
        const gl = this.gl

        if (bufferView.target !== undefined && bufferView.target !== target) {
            throw new Error('BufferView target does not match inferred target')
        }
        const glBuffer = gl.createBuffer() as WebGLBuffer

        gl.bindBuffer(target, glBuffer)
        gl.bufferData(target, this.buffers[bufferView.buffer], gl.STATIC_DRAW, bufferView.byteOffset ?? 0, bufferView.byteLength)

        assert(bufferView.byteStride === undefined || bufferView.byteStride === 0)

        return { buffer: glBuffer, target, stride: bufferView.byteStride ?? 0 }
    }

    parseMaterial(gltfMaterial: GLTF.Material) {
        const material = Material.Default()

        console.log(gltfMaterial)
        assert(gltfMaterial.pbrMetallicRoughness !== undefined)
        assert(gltfMaterial.pbrMetallicRoughness.metallicRoughnessTexture === undefined)
        assert(gltfMaterial.emissiveFactor === undefined)
        assert(gltfMaterial.emissiveTexture === undefined)
        if (gltfMaterial.doubleSided === undefined) {
            console.warn('Double sided material')
        }
        assert(gltfMaterial.alphaCutoff === undefined)
        assert(gltfMaterial.alphaCutoff === undefined)
        assert(gltfMaterial.alphaMode === undefined)
        if (gltfMaterial.normalTexture !== undefined) {
            console.error('NORMAL TEXTURE')
        }
        assert(gltfMaterial.occlusionTexture === undefined)

        if (gltfMaterial.pbrMetallicRoughness.baseColorFactor) {
            const baseColorFactor = new Float32Array(gltfMaterial.pbrMetallicRoughness.baseColorFactor)
            material.useColor(vec3.clone(baseColorFactor as vec3))
        }
        else {
            material.useColor(vec3.fromValues(1, 1, 1))
        }

        if (gltfMaterial.pbrMetallicRoughness.baseColorTexture) {
            const texture = this.gltf.textures![gltfMaterial.pbrMetallicRoughness.baseColorTexture.index]
            assert(texture.source !== undefined)
            const image = this.gltf.images![texture.source]
            assert(texture.sampler !== undefined)
            const sampler = this.gltf.samplers![texture.sampler]
            console.log(sampler)

            assert(image.uri === undefined)

            assert(image.mimeType !== undefined)
            assert(image.mimeType === 'image/png')
            assert(image.bufferView !== undefined)
            const bufferView = this.gltf.bufferViews![image.bufferView]
            assert(bufferView.byteStride === undefined)
            const data = this.buffers[bufferView.buffer].subarray(bufferView.byteOffset ?? 0, (bufferView.byteOffset ?? 0) + bufferView.byteLength)
            const gl = Graphics.context
            const tex = gl.createTexture()!
            gl.bindTexture(gl.TEXTURE_2D, tex)
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, new Uint8Array([255, 0, 255, 255]))

            const blob = new Blob([data], { type:'image/png' })
            const elem = new Image()
            elem.onload = () => {
                gl.bindTexture(gl.TEXTURE_2D, tex)
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, sampler.wrapT ?? gl.REPEAT)
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, sampler.wrapT ?? gl.REPEAT)
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, sampler.minFilter ?? gl.LINEAR)
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, sampler.magFilter ?? gl.LINEAR)
                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, elem)
                gl.generateMipmap(gl.TEXTURE_2D)
            }
            elem.src = (window.URL ?? window.webkitURL).createObjectURL(blob)

            material.useColorMap(tex)
        }

        material.useNormals()
        material.setMetalness(gltfMaterial.pbrMetallicRoughness.metallicFactor ?? 1)
        material.setRoughness(gltfMaterial.pbrMetallicRoughness.roughnessFactor ?? 1)

        return material
    }

    getBufferView(
        accessor: GLTF.Accessor
    ) {
        const gl = this.gl
        const bufferViewIdx = accessor.bufferView
        assert(bufferViewIdx !== undefined)
        if (this.parsedBufferViews[bufferViewIdx] === undefined) {
            this.parsedBufferViews[bufferViewIdx] = this.parseBufferView(this.gltf.bufferViews![bufferViewIdx], gl.ARRAY_BUFFER)
        }
        const bufferView = this.parsedBufferViews[bufferViewIdx]
        const size = ACCESSOR_SIZE[accessor.type]
        assert(
            accessor.componentType === WebGL2RenderingContext['FLOAT'] ||
            accessor.componentType === WebGL2RenderingContext['UNSIGNED_BYTE']
        )
        return new BufferView(bufferView.buffer, size, accessor.componentType, bufferView.stride, accessor.byteOffset ?? 0)
    }

    parseMesh(mesh: GLTF.Mesh, skinIdx?: number): RawMesh {
        const skinned = skinIdx !== undefined
        const skin = skinned ? this.skins.skins[skinIdx] : undefined
        const gl = this.gl
        const primitives: Primitive[] = mesh.primitives.map(primitive => {
            assert(primitive.material !== undefined)

            const material = this.parseMaterial(this.gltf.materials![primitive.material])

            const geometry = new Geometry()
            geometry.mode = primitive.mode ?? 4

            const positions = this.getBufferView(this.gltf.accessors![primitive.attributes['POSITION']]!)
            const normals = this.getBufferView(this.gltf.accessors![primitive.attributes['NORMAL']]!)
            geometry.attributes['Position'] = positions
            geometry.attributes['Normal'] = normals

            if (skin !== undefined) {
                const joints = this.getBufferView(this.gltf.accessors![primitive.attributes['JOINTS_0']]!)
                const weights = this.getBufferView(this.gltf.accessors![primitive.attributes['WEIGHTS_0']]!)
                geometry.attributes['Joints0'] = joints
                geometry.attributes['Weights0'] = weights

                material.useSkin(skin.jointCount)
            }

            if (material.attributes.aUV) {
                const uvs = this.getBufferView(this.gltf.accessors![primitive.attributes['TEXCOORD_0']]!)
                geometry.attributes['UV0'] = uvs
                console.log(primitive.attributes)
            }

            assert(primitive.indices !== undefined)

            const accessor = this.gltf.accessors![primitive.indices]
            const bufferViewIdx = accessor.bufferView
            assert(bufferViewIdx !== undefined)
            if (this.parsedBufferViews[bufferViewIdx] === undefined) {
                this.parsedBufferViews[bufferViewIdx] = this.parseBufferView(this.gltf.bufferViews![bufferViewIdx], gl.ELEMENT_ARRAY_BUFFER)
            }

            const bufferView = this.parsedBufferViews[bufferViewIdx]

            geometry.indices = new BufferView(
                bufferView.buffer, ACCESSOR_SIZE[accessor.type], accessor.componentType, 0, accessor.byteOffset ?? 0
            )
            geometry.count = accessor.count

            return {
                geometry,
                material,
            }
        })
        return {
            primitives,
            skin,
        }
    }

    getAccessorData(accessor: GLTF.Accessor) {
        assert(accessor.bufferView !== undefined)
        assert(accessor.componentType === WebGL2RenderingContext['FLOAT'])
        assert(!accessor.normalized)
        assert(accessor.sparse === undefined)

        const bufferView = this.gltf.bufferViews![accessor.bufferView]
        const buffer = this.buffers![bufferView.buffer]

        assert(bufferView.byteStride === undefined)
        assert(bufferView.target === undefined)

        const numComponent = ACCESSOR_SIZE[accessor.type]
        const offset = (accessor.byteOffset ?? 0) + (bufferView.byteOffset ?? 0)
        const data = new Float32Array(buffer.buffer, offset, accessor.count * numComponent)
        return data
    }

    parseSkins(skinList: GLTF.Skin[]): SkinsInfo {
        const gl = this.gl
        const jointNodesDict: Record<string, JointNode> = {}

        const jointNodes: JointNode[] = []

        const inverseBindMatrices: mat4[] = []
        const jointMatrices: mat4[] = []
        const localForwardMatrices: mat4[] = []
        const globalForwardMatrices: mat4[] = []
        const jointParents: number[] = []

        const skins: GLTFSkin[] = skinList.map((skin) => {
            assert(skin.skeleton === undefined)
            assert(skin.inverseBindMatrices !== undefined)

            const firstNodeIndex = inverseBindMatrices.length

            const inverseBindMatricesAccessor = this.gltf.accessors![skin.inverseBindMatrices]
            assert(inverseBindMatricesAccessor.type === 'MAT4')
            assert(inverseBindMatricesAccessor.componentType === WebGL2RenderingContext['FLOAT'])
            const inverseBindMatricesBuffer = this.getAccessorData(inverseBindMatricesAccessor)

            for (let i = 0; i < skin.joints.length; i++) {
                inverseBindMatrices.push(inverseBindMatricesBuffer.subarray(i*16, i*16+16))
                localForwardMatrices.push(mat4.create())
                globalForwardMatrices.push(mat4.create())
                jointParents.push(-1)
            }

            const glBuffer = gl.createBuffer() as WebGLBuffer

            const buffer = new Float32Array(skin.joints.length * 16)
            for (let i = 0; i < skin.joints.length; i++) {
                jointMatrices.push(buffer.subarray(i*16, i*16+16))
            }

            // Assert the joints form a tree with no outside nodes
            // And find the root nodes
            for (const [i, nodeIdx] of skin.joints.entries()) {
                const node = this.gltf.nodes![nodeIdx]
                assert(node.name !== undefined && (node.name in jointNodesDict) === false)
                assert(node.matrix === undefined)

                const jointNode: JointNode = {
                    name: node.name,
                    defaultRotation: node.rotation ? quat.fromValues(...node.rotation) : quat.create(),
                    defaultTranslation: node.translation ? vec3.fromValues(...node.translation) : vec3.create(),
                    defaultScale: node.scale ? vec3.fromValues(...node.scale) : vec3.fromValues(1,1,1),

                    localForwardMatrix: localForwardMatrices[firstNodeIndex + i],
                    globalForwardMatrix: globalForwardMatrices[firstNodeIndex + i],
                    matrix: jointMatrices[firstNodeIndex + i],

                    parent: null,
                }
                if (node.children) {
                    for (const childIdx of node.children) {
                        const childJointIndex = skin.joints.indexOf(childIdx)
                        // checks if every child is part of the skin
                        // and if the list of joints is ordered (no child should come before its parent in the list)
                        assert(childJointIndex !== -1 && childJointIndex > i)
                        jointParents[childJointIndex] = i
                    }
                }
                const parentIndex = jointParents[firstNodeIndex + i]
                jointNode.parent = parentIndex !== -1 ? jointNodes[parentIndex] : null
                jointNodes.push(jointNode)
            }
            const rootNodes = jointParents.reduce((acc: number[], parent, i) => (parent === -1 ? [...acc, i] : acc), [])
            assert(rootNodes.length === 1)

            return {
                jointStart: firstNodeIndex,
                jointCount: skin.joints.length,
                uboBuffer: glBuffer,
                buffer,
            }
        })

        for (const skin of skins) {
            gl.bindBuffer(gl.UNIFORM_BUFFER, skin.uboBuffer)
            gl.bufferData(gl.UNIFORM_BUFFER, skin.buffer, gl.DYNAMIC_DRAW)
        }

        return {
            skins,
            jointMatrices,

            inverseBindMatrices,
            localForwardMatrices,
            globalForwardMatrices,
            jointParents,

            jointNodes,
        }
    }

    parseAnimation(animation: GLTF.Animation): GLTFRawAnimation {
        const inputIdx = animation.samplers[0].input
        const samplers: {input: Float32Array, output: Float32Array}[] = animation.samplers.map(sampler => {
            // asserts that all samplers share the same input
            assert(sampler.input === inputIdx)
            assert(sampler.interpolation === 'LINEAR' || sampler.interpolation === undefined, 'only linear interpolation is supported')
            return {
                input: this.getAccessorData(this.gltf.accessors![sampler.input]),
                output: this.getAccessorData(this.gltf.accessors![sampler.output]),
            }
        })
        const channels: { input: Float32Array, output: Float32Array, target: { name: string, path: "rotation" | "translation" | "scale" } }[] = []
        for (const channel of animation.channels) {
            const input = samplers[channel.sampler].input
            const output = samplers[channel.sampler].output
            assert(channel.target.node !== undefined)
            const targetName = this.gltf.nodes![channel.target.node].name
            assert(targetName !== undefined)
            assert(channel.target.path === 'translation' || channel.target.path === 'rotation' || channel.target.path === 'scale')
            channels.push({input, output, target: { name: targetName, path: channel.target.path }})
        }
        const inputs = samplers[0].input
        return {
            name: animation.name,
            inputs,
            channels,
            duration: inputs[inputs.length - 1] - inputs[0]
        }
    }
}






