import * as Rapier3D from '@dimforge/rapier3d'
import * as Rapier2D from '@dimforge/rapier2d'
import { Manager } from './Manager'
import { GameObject } from './GameObject'
import { Component } from './Component'

export class RigidBody3DComponent extends Component {
    colliderDesc: Rapier3D.ColliderDesc
    rigidBodyDesc: Rapier3D.RigidBodyDesc
    rigidBody: Rapier3D.RigidBody = <Rapier3D.RigidBody><unknown>null

    constructor(parent: GameObject, colliderDesc: Rapier3D.ColliderDesc, rigidBodyDesc: Rapier3D.RigidBodyDesc) {
        super(parent)

        this.colliderDesc = colliderDesc
        this.rigidBodyDesc = rigidBodyDesc
    }
}

export class Sensor3DComponent extends Component {
    collider: Rapier3D.ColliderDesc
    rigidBodyDesc: Rapier3D.RigidBodyDesc
    rigidBody: Rapier3D.RigidBody = <Rapier3D.RigidBody><unknown>null

    callback: (other: Rapier3D.Collider) => void

    constructor(
        parent: GameObject,
        collider: Rapier3D.ColliderDesc,
        rigidBodyDesc: Rapier3D.RigidBodyDesc,
        collisionCallback: (other: Rapier3D.Collider) => void
    ) {
        super(parent)

        this.collider = collider
        this.rigidBodyDesc = rigidBodyDesc

        this.callback = collisionCallback
    }
}

export class Physics3DManager extends Manager {
    #world: Rapier3D.World = <Rapier3D.World><unknown>null
    RAPIER = <typeof import('@dimforge/rapier3d')><unknown>null

    rigidBodies: Map<RigidBody3DComponent, { component: RigidBody3DComponent, rigidBody: Rapier3D.RigidBody, collider: Rapier3D.Collider }> = new Map()
    sensors: Map<Sensor3DComponent, { component: Sensor3DComponent, rigidBody: Rapier3D.RigidBody, collider: Rapier3D.Collider }> = new Map()

    async Setup(): Promise<void> {
        this.RAPIER = await import('@dimforge/rapier3d')
        const gravity = {x: 0, y: -10, z: 0}
        this.#world = new this.RAPIER.World(gravity)
        this.rigidBodies = new Map()
        this.sensors = new Map()
    }
    Exit() {
        this.#world.free()
        this.rigidBodies.clear()
        this.sensors.clear()
    }

    addRigidBodyComponent(parent: GameObject, collider: Rapier3D.ColliderDesc, rigidBodyDesc: Rapier3D.RigidBodyDesc) {
        const component = new RigidBody3DComponent(parent, collider, rigidBodyDesc)
        parent.addComponent(component)
        GameObject.createListener(parent, 'Init', () => {
            const transform = parent.transform
            component.rigidBodyDesc.setTranslation(transform.position[0], transform.position[1], transform.position[2])
            component.rigidBody = this.addRigidBody(component)
        })
        GameObject.createListener(parent, 'Destroy', () => {
            this.removeRigidBody(component)
        })
    }

    addSensorComponent(
        parent: GameObject,
        colliderDesc: Rapier3D.ColliderDesc,
        rigidBodyDesc: Rapier3D.RigidBodyDesc,
        collisionCallback: (other: GameObject) => void
    ) {
        const component = new Sensor3DComponent(parent, colliderDesc, rigidBodyDesc, ((other: Rapier3D.Collider) => {
            const gameObject = this.getRigidBodyFromColliderHandle(other.handle)!.component.parent
            collisionCallback(gameObject)
        }).bind(this))
        parent.addComponent(component)
        GameObject.createListener(parent, 'Init', () => {
            const transform = component.parent.transform
            component.rigidBodyDesc.setTranslation(transform.position[0], transform.position[1], transform.position[2])
            component.rigidBody = this.addSensor(component)
        })
        GameObject.createListener(parent, 'Destroy', () => {
            throw new Error('not implemented')
        })
    }

    private addRigidBody(component: RigidBody3DComponent) {
        const rigidBody = this.#world.createRigidBody(component.rigidBodyDesc)
        const collider = this.#world.createCollider(component.colliderDesc, rigidBody)
        this.rigidBodies.set(component, {
            component, rigidBody, collider
        })

        return rigidBody
    }

    removeRigidBody(component: RigidBody3DComponent) {
        const rigidBody = this.rigidBodies.get(component)!
        this.#world.removeCollider(rigidBody.collider, false)
        this.#world.removeRigidBody(rigidBody.rigidBody)
        this.rigidBodies.delete(component)
    }

    addSensor(component: Sensor3DComponent) {
        component.collider.setSensor(true)
        const rigidBody = this.#world.createRigidBody(component.rigidBodyDesc)
        const collider = this.#world.createCollider(component.collider, rigidBody)
        this.sensors.set(component, {
            component, rigidBody, collider
        })

        return rigidBody
    }

    removeSensor(component: Sensor3DComponent) {
        const rigidBody = this.sensors.get(component)!
        this.#world.removeCollider(rigidBody.collider, false)
        this.#world.removeRigidBody(rigidBody.rigidBody)
        this.sensors.delete(component)
    }

    raycast(ray: Rapier3D.Ray, maxToi: number, solid: boolean, filterGroups?: number) {
        return this.#world.castRay(ray, maxToi, solid, undefined, filterGroups)
    }

    getRigidBodyFromColliderHandle(handle: number) {
        for (const rigidBody of this.rigidBodies.values()) {
            if (rigidBody.collider.handle === handle) {
                return rigidBody
            }
        }
        for (const rigidBody of this.sensors.values()) {
            if (rigidBody.collider.handle === handle) {
                return rigidBody
            }
        }
    }

    PhysicsUpdate() {
        this.#world.step()

        for (const rigidBody of this.rigidBodies.values()) {
            const position = rigidBody.rigidBody.translation()
            const pos = rigidBody.component.parent.transform.position
            pos[0] = position.x
            pos[1] = position.y
        }
        for (const sensor of this.sensors.values()) {
            this.#world.intersectionsWith(sensor.collider, sensor.component.callback)
            const position = sensor.rigidBody.translation()
            const pos = sensor.component.parent.transform.position
            pos[0] = position.x
            pos[1] = position.y
        }
    }
}

export class RigidBody2DComponent extends Component {
    colliderDesc: Rapier2D.ColliderDesc
    rigidBodyDesc: Rapier2D.RigidBodyDesc
    rigidBody: Rapier2D.RigidBody = <Rapier2D.RigidBody><unknown>null

    constructor(parent: GameObject, colliderDesc: Rapier2D.ColliderDesc, rigidBodyDesc: Rapier2D.RigidBodyDesc) {
        super(parent)

        this.colliderDesc = colliderDesc
        this.rigidBodyDesc = rigidBodyDesc
    }
}

export class Sensor2DComponent extends Component {
    collider: Rapier2D.ColliderDesc
    rigidBodyDesc: Rapier2D.RigidBodyDesc
    rigidBody: Rapier2D.RigidBody = <Rapier2D.RigidBody><unknown>null

    callback: (other: Rapier2D.Collider) => void

    constructor(
        parent: GameObject,
        collider: Rapier2D.ColliderDesc,
        rigidBodyDesc: Rapier2D.RigidBodyDesc,
        collisionCallback: (other: Rapier2D.Collider) => void
    ) {
        super(parent)

        this.collider = collider
        this.rigidBodyDesc = rigidBodyDesc

        this.callback = collisionCallback
    }
}

export class Physics2DManager extends Manager {
    #world: Rapier2D.World = <Rapier2D.World><unknown>null
    RAPIER = <typeof import('@dimforge/rapier2d')><unknown>null

    rigidBodies: Map<RigidBody2DComponent, { component: RigidBody2DComponent, rigidBody: Rapier2D.RigidBody, collider: Rapier2D.Collider }> = new Map()
    sensors: Map<Sensor2DComponent, { component: Sensor2DComponent, rigidBody: Rapier2D.RigidBody, collider: Rapier2D.Collider }> = new Map()

    async Setup(): Promise<void> {
        this.RAPIER = await import('@dimforge/rapier2d')
        const gravity = {x: 0, y: 0}
        this.#world = new this.RAPIER.World(gravity)
        this.rigidBodies = new Map()
        this.sensors = new Map()
    }
    Exit() {
        this.#world.free()
        this.rigidBodies.clear()
        this.sensors.clear()
    }

    addRigidBodyComponent(parent: GameObject, collider: Rapier2D.ColliderDesc, rigidBodyDesc: Rapier2D.RigidBodyDesc) {
        const component = new RigidBody2DComponent(parent, collider, rigidBodyDesc)
        parent.addComponent(component)
        GameObject.createListener(parent, 'Init', () => {
            const transform = parent.transform
            component.rigidBodyDesc.setTranslation(transform.position[0], transform.position[1])
            component.rigidBody = this.addRigidBody(component)
        })
        GameObject.createListener(parent, 'Destroy', () => {
            this.removeRigidBody(component)
        })
    }

    addSensorComponent(
        parent: GameObject,
        colliderDesc: Rapier2D.ColliderDesc,
        rigidBodyDesc: Rapier2D.RigidBodyDesc,
        collisionCallback: (other: GameObject) => void
    ) {
        const component = new Sensor2DComponent(parent, colliderDesc, rigidBodyDesc, ((other: Rapier2D.Collider) => {
            const gameObject = this.getRigidBodyFromColliderHandle(other.handle)!.component.parent
            collisionCallback(gameObject)
        }).bind(this))
        parent.addComponent(component)
        GameObject.createListener(parent, 'Init', () => {
            const transform = component.parent.transform
            component.rigidBodyDesc.setTranslation(transform.position[0], transform.position[1])
            component.rigidBody = this.addSensor(component)
        })
        GameObject.createListener(parent, 'Destroy', () => {
            throw new Error('not implemented')
        })
    }

    private addRigidBody(component: RigidBody2DComponent) {
        const rigidBody = this.#world.createRigidBody(component.rigidBodyDesc)
        const collider = this.#world.createCollider(component.colliderDesc, rigidBody)
        this.rigidBodies.set(component, {
            component, rigidBody, collider
        })

        return rigidBody
    }

    removeRigidBody(component: RigidBody2DComponent) {
        const rigidBody = this.rigidBodies.get(component)!
        this.#world.removeCollider(rigidBody.collider, false)
        this.#world.removeRigidBody(rigidBody.rigidBody)
        this.rigidBodies.delete(component)
    }

    addSensor(component: Sensor2DComponent) {
        component.collider.setSensor(true)
        const rigidBody = this.#world.createRigidBody(component.rigidBodyDesc)
        const collider = this.#world.createCollider(component.collider, rigidBody)
        this.sensors.set(component, {
            component, rigidBody, collider
        })

        return rigidBody
    }

    removeSensor(component: Sensor2DComponent) {
        const rigidBody = this.sensors.get(component)!
        this.#world.removeCollider(rigidBody.collider, false)
        this.#world.removeRigidBody(rigidBody.rigidBody)
        this.sensors.delete(component)
    }

    raycast(ray: Rapier2D.Ray, maxToi: number, solid: boolean, filterGroups?: number) {
        return this.#world.castRay(ray, maxToi, solid, undefined, filterGroups)
    }

    getRigidBodyFromColliderHandle(handle: number) {
        for (const rigidBody of this.rigidBodies.values()) {
            if (rigidBody.collider.handle === handle) {
                return rigidBody
            }
        }
        for (const rigidBody of this.sensors.values()) {
            if (rigidBody.collider.handle === handle) {
                return rigidBody
            }
        }
    }

    PhysicsUpdate() {
        this.#world.step()

        for (const rigidBody of this.rigidBodies.values()) {
            const position = rigidBody.rigidBody.translation()
            const pos = rigidBody.component.parent.transform.position
            pos[0] = position.x
            pos[1] = position.y
        }
        for (const sensor of this.sensors.values()) {
            this.#world.intersectionsWith(sensor.collider, sensor.component.callback)
            const position = sensor.rigidBody.translation()
            const pos = sensor.component.parent.transform.position
            pos[0] = position.x
            pos[1] = position.y
        }
    }
}

export const Physics2D = new Physics2DManager()
export const Physics3D = new Physics3DManager()
